import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { Conference, IConference } from "./conference";
import { IMenu, Menu } from "./menu";
import { IMinistry, Ministry } from "./ministry";

export interface ISectionDomainObjectHeaderFields {
  internalTitle?: never;
  name?: string;
  domainObject: ILink<'Entry'> | SectionDomainObjectHeaderDomainObject;
  secondaryNav?: ILink<'Entry'> | IMenu;
}

export type SectionDomainObjectHeaderDomainObject = IConference | IMinistry;
export type SectionDomainObjectHeaderDomainObjectClass = Conference | Ministry;

/**
 * Section: Ministry or Conference Header
 * A Ministry or Conference Header section includes the Ministry or Conference's colors and logo, to be displayed at the top of a page related to that Ministry or Conference.
 */
export interface ISectionDomainObjectHeader extends IEntry<ISectionDomainObjectHeaderFields> {
}

export function isSectionDomainObjectHeader(entry: IEntry<any>): entry is ISectionDomainObjectHeader {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-domain-object-header'
}

export class SectionDomainObjectHeader extends Entry<ISectionDomainObjectHeaderFields> implements ISectionDomainObjectHeader {
  get name(): string | undefined {
    return this.fields.name
  }

  get domainObject(): SectionDomainObjectHeaderDomainObjectClass | null {
    return (isEntry(this.fields.domainObject) ? wrap<'conference' | 'ministry'>(this.fields.domainObject) : null)
  }

  get domain_object(): SectionDomainObjectHeaderDomainObjectClass | null {
    return (isEntry(this.fields.domainObject) ? wrap<'conference' | 'ministry'>(this.fields.domainObject) : null)
  }

  get secondaryNav(): Menu | null | undefined {
    return !this.fields.secondaryNav ? undefined :
      (isEntry(this.fields.secondaryNav) ? wrap<'menu'>(this.fields.secondaryNav) : null)
  }

  get secondary_nav(): Menu | null | undefined {
    return !this.fields.secondaryNav ? undefined :
      (isEntry(this.fields.secondaryNav) ? wrap<'menu'>(this.fields.secondaryNav) : null)
  }

  constructor(entry: ISectionDomainObjectHeader);
  constructor(id: string, fields: ISectionDomainObjectHeaderFields);
  constructor(entryOrId: ISectionDomainObjectHeader | string, fields?: ISectionDomainObjectHeaderFields) {
    super(entryOrId, 'section-domain-object-header', fields)
  }
}
