import React, { useEffect } from "react"
import { RegionDropdown } from 'react-country-region-selector'

interface IProps {
  stateZipApplies: boolean
  country: string
  state: string
  label: string
  onChange: (val: string) => void
}

const fieldProps = {
  required: '',
}

export function StateSelect(props: IProps) {
  const { stateZipApplies, country, state, label, onChange } = props
  if (stateZipApplies) {
    return (
      <div className="select-field">
        <RegionDropdown
          defaultOptionLabel={`Select a ${label}`}
          onChange={onChange}
          name='state'
          country={country}
          value={state}
          {...fieldProps} />
      </div>
    )
  } else {
    return (
      <input
        type='text'
        minLength={3}
        name='state'
        value={state}
        required={true}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  }
}