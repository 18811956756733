import { Entry, IEntry } from "../base";

export interface ISectionMarqueeTextFields {
  internalTitle?: never;
  tag?: string;
  title: string;
  body?: string;
  bookmarkTitle?: string;
}

/**
 * Section: Marquee Text
 * Display a large section title and an enlarged single paragraph along with an optional tagline at the top.
 */
export interface ISectionMarqueeText extends IEntry<ISectionMarqueeTextFields> {
}

export function isSectionMarqueeText(entry: IEntry<any>): entry is ISectionMarqueeText {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-marquee-text'
}

export class SectionMarqueeText extends Entry<ISectionMarqueeTextFields> implements ISectionMarqueeText {
  get tag(): string | undefined {
    return this.fields.tag
  }

  get title(): string {
    return this.fields.title
  }

  get body(): string | undefined {
    return this.fields.body
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionMarqueeText);
  constructor(id: string, fields: ISectionMarqueeTextFields);
  constructor(entryOrId: ISectionMarqueeText | string, fields?: ISectionMarqueeTextFields) {
    super(entryOrId, 'section-marquee-text', fields)
  }
}
