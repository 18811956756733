import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { IPage, Page } from "./page";
import { IResourceSet, ResourceSet } from "./resource_set";
import { ISectionBlockText, SectionBlockText } from "./section-block-text";
import { ISectionCard, SectionCard } from "./section-card";
import { ISectionCodeWidget, SectionCodeWidget } from "./section-code-widget";
import { ISectionContactUs, SectionContactUs } from "./section-contact-us";
import { ISectionDomainObjectHeader, SectionDomainObjectHeader } from "./section-domain-object-header";
import { ISectionEmailCapture, SectionEmailCapture } from "./section-email-capture";
import { ISectionFaq, SectionFaq } from "./section-faq";
import { ISectionFeaturedItems, SectionFeaturedItems } from "./section-featured-items";
import { ISectionHero, SectionHero } from "./section-hero";
import { ISectionImageGallery, SectionImageGallery } from "./section-image-gallery";
import { ISectionIntro, SectionIntro } from "./section-intro";
import { ISectionLocationMap, SectionLocationMap } from "./section-location-map";
import { ISectionMarqueeText, SectionMarqueeText } from "./section-marquee-text";
import { ISectionMinistryDetails, SectionMinistryDetails } from "./section-ministry-details";
import { ISectionPartnerChurches, SectionPartnerChurches } from "./section-partner-churches";
import { ISectionProductList, SectionProductList } from "./section-product-list";
import { ISectionTestimonials, SectionTestimonials } from "./section-testimonials";
import { ISectionVideo, SectionVideo } from "./section-video";
import { ISectionVideoHighlight, SectionVideoHighlight } from "./section-video-highlight";

export interface IMenuButtonFields {
  internalTitle?: never;
  text?: string;
  overline?: string;
  icon?: ILink<'Asset'> | IAsset;
  externalLink?: string;
  resourceSet?: ILink<'Entry'> | IResourceSet;
  link?: ILink<'Entry'> | IPage;
  sectionLink?: ILink<'Entry'> | MenuButtonSectionLink;
  ionIcon?: string;
  style?: MenuButtonStyle;
  preserveIncomingUtmParams?: boolean;
}

export type MenuButtonSectionLink = ISectionBlockText | ISectionCard | ISectionCodeWidget | ISectionContactUs | ISectionEmailCapture | ISectionFaq | ISectionFeaturedItems | ISectionHero | ISectionImageGallery | ISectionIntro | ISectionLocationMap | ISectionMarqueeText | ISectionMinistryDetails | ISectionDomainObjectHeader | ISectionPartnerChurches | ISectionProductList | ISectionTestimonials | ISectionVideo | ISectionVideoHighlight;
export type MenuButtonSectionLinkClass = SectionBlockText | SectionCard | SectionCodeWidget | SectionContactUs | SectionEmailCapture | SectionFaq | SectionFeaturedItems | SectionHero | SectionImageGallery | SectionIntro | SectionLocationMap | SectionMarqueeText | SectionMinistryDetails | SectionDomainObjectHeader | SectionPartnerChurches | SectionProductList | SectionTestimonials | SectionVideo | SectionVideoHighlight;
export type MenuButtonStyle = 'oval-border';

/**
 * Menu Button
 * A Menu Button is a clickable button that goes on a Menu.  It has a link to a Page or a URL.
 */
export interface IMenuButton extends IEntry<IMenuButtonFields> {
}

export function isMenuButton(entry: IEntry<any>): entry is IMenuButton {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'menuButton'
}

export class MenuButton extends Entry<IMenuButtonFields> implements IMenuButton {
  get text(): string | undefined {
    return this.fields.text
  }

  get overline(): string | undefined {
    return this.fields.overline
  }

  get icon(): Asset | null | undefined {
    return !this.fields.icon ? undefined :
      (isAsset(this.fields.icon) ? new Asset(this.fields.icon) : null)
  }

  get externalLink(): string | undefined {
    return this.fields.externalLink
  }

  get external_link(): string | undefined {
    return this.fields.externalLink
  }

  get resourceSet(): ResourceSet | null | undefined {
    return !this.fields.resourceSet ? undefined :
      (isEntry(this.fields.resourceSet) ? wrap<'resourceSet'>(this.fields.resourceSet) : null)
  }

  get resource_set(): ResourceSet | null | undefined {
    return !this.fields.resourceSet ? undefined :
      (isEntry(this.fields.resourceSet) ? wrap<'resourceSet'>(this.fields.resourceSet) : null)
  }

  get link(): Page | null | undefined {
    return !this.fields.link ? undefined :
      (isEntry(this.fields.link) ? wrap<'page'>(this.fields.link) : null)
  }

  get sectionLink(): MenuButtonSectionLinkClass | null | undefined {
    return !this.fields.sectionLink ? undefined :
      (isEntry(this.fields.sectionLink) ? wrap<'section-block-text' | 'section-card' | 'section-code-widget' | 'section-contact-us' | 'section-email-capture' | 'section-faq' | 'section-featured-items' | 'section-hero' | 'section-image-gallery' | 'section-intro' | 'section-location-map' | 'section-marquee-text' | 'section-ministry-details' | 'section-domain-object-header' | 'section-partner-churches' | 'section-product-list' | 'section-testimonials' | 'section-video' | 'section-video-highlight'>(this.fields.sectionLink) : null)
  }

  get section_link(): MenuButtonSectionLinkClass | null | undefined {
    return !this.fields.sectionLink ? undefined :
      (isEntry(this.fields.sectionLink) ? wrap<'section-block-text' | 'section-card' | 'section-code-widget' | 'section-contact-us' | 'section-email-capture' | 'section-faq' | 'section-featured-items' | 'section-hero' | 'section-image-gallery' | 'section-intro' | 'section-location-map' | 'section-marquee-text' | 'section-ministry-details' | 'section-domain-object-header' | 'section-partner-churches' | 'section-product-list' | 'section-testimonials' | 'section-video' | 'section-video-highlight'>(this.fields.sectionLink) : null)
  }

  get ionIcon(): string | undefined {
    return this.fields.ionIcon
  }

  get ion_icon(): string | undefined {
    return this.fields.ionIcon
  }

  get style(): MenuButtonStyle | undefined {
    return this.fields.style
  }

  get preserveIncomingUtmParams(): boolean | undefined {
    return this.fields.preserveIncomingUtmParams
  }

  get preserve_incoming_utm_params(): boolean | undefined {
    return this.fields.preserveIncomingUtmParams
  }

  constructor(entry: IMenuButton);
  constructor(id: string, fields: IMenuButtonFields);
  constructor(entryOrId: IMenuButton | string, fields?: IMenuButtonFields) {
    super(entryOrId, 'menuButton', fields)
  }
}
