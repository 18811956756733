import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { Conference, IConference } from "./conference";
import { IMenu, Menu } from "./menu";
import { IMenuButton, MenuButton } from "./menu_button";
import { IMinistry, Ministry } from "./ministry";
import { IPageMetadata, PageMetadata } from "./page_metadata";
import { ISectionDomainObjectHeader, SectionDomainObjectHeader } from "./section-domain-object-header";
import { ISectionHero, SectionHero } from "./section-hero";

export interface IPageFields {
  internalTitle?: never;
  title: string;
  slug: string;
  isResourceLandingPage?: boolean;
  allowSelfServiceFlow?: boolean;
  theme?: PageTheme;
  skin?: PageSkin;
  meta?: ILink<'Entry'> | IPageMetadata;
  menu?: ILink<'Entry'> | IMenu;
  primaryCta?: ILink<'Entry'> | IMenuButton;
  header?: ILink<'Entry'> | PageHeader;
  sections?: Array<ILink<'Entry'> | IEntry<any>>;
  subpages?: Array<ILink<'Entry'> | IPage>;
  domainObject?: ILink<'Entry'> | PageDomainObject;
  minimumSecurityLevel?: PageMinimumSecurityLevel;
}

export type PageTheme = 'CLC' | 'Leader Resource' | 'Help' | 'CLC 2023';
export type PageSkin = 'default' | 'dark';
export type PageHeader = ISectionHero | ISectionDomainObjectHeader;
export type PageHeaderClass = SectionHero | SectionDomainObjectHeader;
export type PageDomainObject = IMinistry | IConference;
export type PageDomainObjectClass = Ministry | Conference;
export type PageMinimumSecurityLevel = 'Anonymous' | 'Mentor' | 'Lay Leader' | 'Church Admin' | 'WMR Admin';

/**
 * Page
 * A page describes a collection of sections that correspondto a URL slug
 */
export interface IPage extends IEntry<IPageFields> {
}

export function isPage(entry: IEntry<any>): entry is IPage {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'page'
}

export class Page extends Entry<IPageFields> implements IPage {
  get title(): string {
    return this.fields.title
  }

  get slug(): string {
    return this.fields.slug
  }

  get isResourceLandingPage(): boolean | undefined {
    return this.fields.isResourceLandingPage
  }

  get is_resource_landing_page(): boolean | undefined {
    return this.fields.isResourceLandingPage
  }

  get allowSelfServiceFlow(): boolean | undefined {
    return this.fields.allowSelfServiceFlow
  }

  get allow_self_service_flow(): boolean | undefined {
    return this.fields.allowSelfServiceFlow
  }

  get theme(): PageTheme | undefined {
    return this.fields.theme
  }

  get skin(): PageSkin | undefined {
    return this.fields.skin
  }

  get meta(): PageMetadata | null | undefined {
    return !this.fields.meta ? undefined :
      (isEntry(this.fields.meta) ? wrap<'pageMetadata'>(this.fields.meta) : null)
  }

  get menu(): Menu | null | undefined {
    return !this.fields.menu ? undefined :
      (isEntry(this.fields.menu) ? wrap<'menu'>(this.fields.menu) : null)
  }

  get primaryCta(): MenuButton | null | undefined {
    return !this.fields.primaryCta ? undefined :
      (isEntry(this.fields.primaryCta) ? wrap<'menuButton'>(this.fields.primaryCta) : null)
  }

  get primary_cta(): MenuButton | null | undefined {
    return !this.fields.primaryCta ? undefined :
      (isEntry(this.fields.primaryCta) ? wrap<'menuButton'>(this.fields.primaryCta) : null)
  }

  get header(): PageHeaderClass | null | undefined {
    return !this.fields.header ? undefined :
      (isEntry(this.fields.header) ? wrap<'section-hero' | 'section-domain-object-header'>(this.fields.header) : null)
  }

  get sections(): Array<IEntry<any> | null> | undefined {
    return !this.fields.sections ? undefined :
      this.fields.sections.map((item) =>
        isEntry(item) ? wrap(item) : null
      )
  }

  get subpages(): Array<Page | null> | undefined {
    return !this.fields.subpages ? undefined :
      this.fields.subpages.map((item) =>
        isEntry(item) ? wrap<'page'>(item) : null
      )
  }

  get domainObject(): PageDomainObjectClass | null | undefined {
    return !this.fields.domainObject ? undefined :
      (isEntry(this.fields.domainObject) ? wrap<'ministry' | 'conference'>(this.fields.domainObject) : null)
  }

  get domain_object(): PageDomainObjectClass | null | undefined {
    return !this.fields.domainObject ? undefined :
      (isEntry(this.fields.domainObject) ? wrap<'ministry' | 'conference'>(this.fields.domainObject) : null)
  }

  get minimumSecurityLevel(): PageMinimumSecurityLevel | undefined {
    return this.fields.minimumSecurityLevel
  }

  get minimum_security_level(): PageMinimumSecurityLevel | undefined {
    return this.fields.minimumSecurityLevel
  }

  constructor(entry: IPage);
  constructor(id: string, fields: IPageFields);
  constructor(entryOrId: IPage | string, fields?: IPageFields) {
    super(entryOrId, 'page', fields)
  }
}
