import React from "react"
import { IMinistry } from "../../lib/contentful/generated"

interface IProps {
  ministry: IMinistry,
  loginUrl: string,
}

interface IState {
  email: string
}

export class SignUpForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      email: '',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  public render() {
    const { ministry } = this.props

    return (
      <>
        <h4 className="self-service__form-question">
          Want to access {ministry.fields.title} leader resources?
        </h4>
        <form onSubmit={this.handleSubmit}>
          <label>
            Sign up to gain access
            <input type="email"
              placeholder="person@mail.com"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </label>
          <div className='self-service__form-btn'>
            <input className="btn btn-primary" type="submit" value="Sign Up" />
          </div>
        </form>
        <p>When you create an account, we’ll ask for your name and a password. Later we’ll ask you for your home church.</p>
        <div className='self-service__form-sign-in'>
          <a href='/users/sign_in' >Already have an account? Sign In.</a>
        </div></>
    )
  }

  public async handleSubmit(e) {
    e.preventDefault()

    // Construct the new_user_session_url like in #maybe_redirect_to_sign_in
    const url = new URL(window.location.href)
    url.pathname = '/sign_in_with_email'
    url.searchParams.set('email', this.state.email)
    url.searchParams.set('origin', window.location.href)

    window.location.href = url.toString()

    this.setState({
      email: '',
    })
  }
}
