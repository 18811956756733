import * as React from 'react';
import { connectMapRefinement } from "../connectors/connectMapRefinement";
const PlacePill = connectMapRefinement((props) => {
    if (!props.currentRefinement || !props.currentRefinement.selectedPlace) {
        return null;
    }
    const { selectedPlace } = props.currentRefinement;
    return (React.createElement("span", { className: "badge badge-pill badge-info location-map__pane__place-pill", onClick: () => props.refine({
            selectedPlace: undefined,
            expandSearch: false,
        }) },
        selectedPlace.name,
        React.createElement("a", { className: "material-icons", style: { paddingLeft: '2px' } }, "close")));
});
export default PlacePill;
