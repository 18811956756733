import React from "react"

export const ModalText = (props) => {
  const { user, reassignment } = props

  if (reassignment) {
    return (
      <h4 className="self-service__form-question">
      {`This will reassign ${user.first_name} ${user.last_name} to another church.`}
    </h4>
    )
  } else {
    return (
      <>
        <h4 className="self-service__form-question">
          Welcome, {user.first_name}. Just one more question. What is your home church?
        </h4>
        <h4 className="self-service__form-question" style={{ padding: 0 }}>
          Let&apos;s see if we already know about your church.
        </h4>
      </>
    )
  }
}