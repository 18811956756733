import { Asset, Entry, IAsset, IEntry, ILink, isAsset } from "../base";

export interface ISectionImageGalleryFields {
  internalTitle?: never;
  images?: Array<ILink<'Asset'> | IAsset>;
  bookmarkTitle?: string;
}

/**
 * Section: Image Gallery
 * An Image Gallery Section displays a rotating gallery of severalimages in a horizontal row on the page.
 */
export interface ISectionImageGallery extends IEntry<ISectionImageGalleryFields> {
}

export function isSectionImageGallery(entry: IEntry<any>): entry is ISectionImageGallery {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-image-gallery'
}

export class SectionImageGallery extends Entry<ISectionImageGalleryFields> implements ISectionImageGallery {
  get images(): Array<Asset | null> | undefined {
    return !this.fields.images ? undefined :
      this.fields.images.map((item) =>
        isAsset(item) ? new Asset(item) : null
      )
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionImageGallery);
  constructor(id: string, fields: ISectionImageGalleryFields);
  constructor(entryOrId: ISectionImageGallery | string, fields?: ISectionImageGalleryFields) {
    super(entryOrId, 'section-image-gallery', fields)
  }
}
