import { Asset, Entry, IAsset, IEntry, ILink, isAsset } from "../base";

export interface ICardFields {
  internalTitle?: string;
  cardImage?: ILink<'Asset'> | IAsset;
  title?: string;
  overline?: string;
  cardImageHref?: string;
  description?: string;
  styles?: CardStyle[];
}

export type CardStyle = 'image-only';

/**
 * Card
 */
export interface ICard extends IEntry<ICardFields> {
}

export function isCard(entry: IEntry<any>): entry is ICard {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'card'
}

export class Card extends Entry<ICardFields> implements ICard {
  get internalTitle(): string | undefined {
    return this.fields.internalTitle
  }

  get internal_title(): string | undefined {
    return this.fields.internalTitle
  }

  get cardImage(): Asset | null | undefined {
    return !this.fields.cardImage ? undefined :
      (isAsset(this.fields.cardImage) ? new Asset(this.fields.cardImage) : null)
  }

  get card_image(): Asset | null | undefined {
    return !this.fields.cardImage ? undefined :
      (isAsset(this.fields.cardImage) ? new Asset(this.fields.cardImage) : null)
  }

  get title(): string | undefined {
    return this.fields.title
  }

  get overline(): string | undefined {
    return this.fields.overline
  }

  get cardImageHref(): string | undefined {
    return this.fields.cardImageHref
  }

  get card_image_href(): string | undefined {
    return this.fields.cardImageHref
  }

  get description(): string | undefined {
    return this.fields.description
  }

  get styles(): CardStyle[] | undefined {
    return this.fields.styles
  }

  constructor(entry: ICard);
  constructor(id: string, fields: ICardFields);
  constructor(entryOrId: ICard | string, fields?: ICardFields) {
    super(entryOrId, 'card', fields)
  }
}
