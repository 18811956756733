import { useAsyncAction } from "./useAsyncAction"
import { IInvitation } from "../models/invitation"
import IUser from "../models/user"
import { useState } from "react"
import { localFetch } from "../local_fetch"
import { Stringable } from "../types"

interface IFormData {
  church_name: string
  state: string
  city: string
  country: string
}

interface IProps {
  securityLevelId: number
  accountId: Stringable
  user: IUser
  ministryId: string
  reassign?: boolean
  membershipId?: string
}

export function useCreateInvitation() {
  const [{data, error, wait}, createInvitation] = useAsyncAction(async (formData: IFormData, props: IProps) => {
    const invite: Partial<IInvitation> = {
      email: props.user.email,
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      ministry_id: props.ministryId,
      account_id: props.accountId,
      security_level_id: props.securityLevelId,
      note: formData ? formData : null,
      reassign: props?.reassign,
      membershipId: props?.membershipId
    }

    const response = await localFetch(`/invitations/provisional?origin=${window.location.href}`, {
      method: 'POST',
      body: JSON.stringify(invite),
      redirect: 'follow'
    })

    if (response.status >= 400) {
      let body: any
      try {
        body = await response.json()
      } catch (e) {
        body = {}
      }
      throw new Error(body.error_message || `Something went wrong: ${response.statusText}`)
    }

    return await response.json() as { invitation: IInvitation, success_message: string }
  })

  return [
    {
      data,
      error,
      wait
    },
    createInvitation
  ] as const
}
