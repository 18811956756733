import { format, toDate, utcToZonedTime } from 'date-fns-tz';
/**
 * Parses a date string like '2020-01-02' assuming it is in the given time zone.
 *
 * The problem:
 *  If you do Date.parse('2020-01-02'), javascript assumes it is in UTC and then
 * converts it to '2020-01-01T18:00:00-06:00'.  Trying to render this to the user
 * shows Jan 1 rather than the expected Jan 2.
 *
 * Since we already have a known time zone reference for this date, this function
 * assumes the date is in that time zone.  Thus '2020-01-02' converts to
 * '2020-01-02T00:00:00-06:00' and the date is displayed as expected.
 *
 * @param date The date string to parse (see parseTzlessDate.jest.ts for formats)
 * @param timeZone Optional time zone name.  By default uses current browser TZ.
 */
export function parseTzlessDate(date, timeZone) {
    timeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const inUtc = toDate(date, { timeZone });
    const inTimeZone = utcToZonedTime(inUtc, timeZone);
    return format(inTimeZone, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', { timeZone });
}
