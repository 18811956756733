import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { IMenuButton, MenuButton } from "./menu_button";

export interface ISectionHttpErrorFields {
  internalTitle: string;
  errorCode: number;
  text: string;
  actionButton?: Array<ILink<'Entry'> | IMenuButton>;
  backgroundImage: ILink<'Asset'> | IAsset;
}

/**
 * Section: Http Error
 */
export interface ISectionHttpError extends IEntry<ISectionHttpErrorFields> {
}

export function isSectionHttpError(entry: IEntry<any>): entry is ISectionHttpError {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-http-error'
}

export class SectionHttpError extends Entry<ISectionHttpErrorFields> implements ISectionHttpError {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get errorCode(): number {
    return this.fields.errorCode
  }

  get error_code(): number {
    return this.fields.errorCode
  }

  get text(): string {
    return this.fields.text
  }

  get actionButton(): Array<MenuButton | null> | undefined {
    return !this.fields.actionButton ? undefined :
      this.fields.actionButton.map((item) =>
        isEntry(item) ? wrap<'menuButton'>(item) : null
      )
  }

  get action_button(): Array<MenuButton | null> | undefined {
    return !this.fields.actionButton ? undefined :
      this.fields.actionButton.map((item) =>
        isEntry(item) ? wrap<'menuButton'>(item) : null
      )
  }

  get backgroundImage(): Asset | null {
    return (isAsset(this.fields.backgroundImage) ? new Asset(this.fields.backgroundImage) : null)
  }

  get background_image(): Asset | null {
    return (isAsset(this.fields.backgroundImage) ? new Asset(this.fields.backgroundImage) : null)
  }

  constructor(entry: ISectionHttpError);
  constructor(id: string, fields: ISectionHttpErrorFields);
  constructor(entryOrId: ISectionHttpError | string, fields?: ISectionHttpErrorFields) {
    super(entryOrId, 'section-http-error', fields)
  }
}
