import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { IMenuButton, MenuButton } from "./menu_button";

export interface ISectionCardFields {
  internalTitle?: never;
  title: string;
  summary?: string;
  photo?: ILink<'Asset'> | IAsset;
  actionButton?: ILink<'Entry'> | IMenuButton;
  caption?: string;
}

/**
 * Section: Card
 */
export interface ISectionCard extends IEntry<ISectionCardFields> {
}

export function isSectionCard(entry: IEntry<any>): entry is ISectionCard {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-card'
}

export class SectionCard extends Entry<ISectionCardFields> implements ISectionCard {
  get title(): string {
    return this.fields.title
  }

  get summary(): string | undefined {
    return this.fields.summary
  }

  get photo(): Asset | null | undefined {
    return !this.fields.photo ? undefined :
      (isAsset(this.fields.photo) ? new Asset(this.fields.photo) : null)
  }

  get actionButton(): MenuButton | null | undefined {
    return !this.fields.actionButton ? undefined :
      (isEntry(this.fields.actionButton) ? wrap<'menuButton'>(this.fields.actionButton) : null)
  }

  get action_button(): MenuButton | null | undefined {
    return !this.fields.actionButton ? undefined :
      (isEntry(this.fields.actionButton) ? wrap<'menuButton'>(this.fields.actionButton) : null)
  }

  get caption(): string | undefined {
    return this.fields.caption
  }

  constructor(entry: ISectionCard);
  constructor(id: string, fields: ISectionCardFields);
  constructor(entryOrId: ISectionCard | string, fields?: ISectionCardFields) {
    super(entryOrId, 'section-card', fields)
  }
}
