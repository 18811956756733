/**
 * Reimplementation of lodash.pick so we can drop lodash as a dependency.
 */
export function pick(obj, keys) {
    const ret = {};
    keys.forEach(key => {
        ret[key] = obj[key];
    });
    return ret;
}
