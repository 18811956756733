import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { IMenuButton, MenuButton } from "./menu_button";

export interface ISectionHeroFields {
  internalTitle?: never;
  backgroundImage?: ILink<'Asset'> | IAsset;
  mobileBackgroundImage?: ILink<'Asset'> | IAsset;
  text: string;
  subtext?: string;
  primaryButton?: ILink<'Entry'> | IMenuButton;
  secondaryButton?: ILink<'Entry'> | IMenuButton;
  style?: SectionHeroStyle;
}

export type SectionHeroStyle = 'default' | 'large';

/**
 * Section: Hero
 */
export interface ISectionHero extends IEntry<ISectionHeroFields> {
}

export function isSectionHero(entry: IEntry<any>): entry is ISectionHero {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-hero'
}

export class SectionHero extends Entry<ISectionHeroFields> implements ISectionHero {
  get backgroundImage(): Asset | null | undefined {
    return !this.fields.backgroundImage ? undefined :
      (isAsset(this.fields.backgroundImage) ? new Asset(this.fields.backgroundImage) : null)
  }

  get background_image(): Asset | null | undefined {
    return !this.fields.backgroundImage ? undefined :
      (isAsset(this.fields.backgroundImage) ? new Asset(this.fields.backgroundImage) : null)
  }

  get mobileBackgroundImage(): Asset | null | undefined {
    return !this.fields.mobileBackgroundImage ? undefined :
      (isAsset(this.fields.mobileBackgroundImage) ? new Asset(this.fields.mobileBackgroundImage) : null)
  }

  get mobile_background_image(): Asset | null | undefined {
    return !this.fields.mobileBackgroundImage ? undefined :
      (isAsset(this.fields.mobileBackgroundImage) ? new Asset(this.fields.mobileBackgroundImage) : null)
  }

  get text(): string {
    return this.fields.text
  }

  get subtext(): string | undefined {
    return this.fields.subtext
  }

  get primaryButton(): MenuButton | null | undefined {
    return !this.fields.primaryButton ? undefined :
      (isEntry(this.fields.primaryButton) ? wrap<'menuButton'>(this.fields.primaryButton) : null)
  }

  get primary_button(): MenuButton | null | undefined {
    return !this.fields.primaryButton ? undefined :
      (isEntry(this.fields.primaryButton) ? wrap<'menuButton'>(this.fields.primaryButton) : null)
  }

  get secondaryButton(): MenuButton | null | undefined {
    return !this.fields.secondaryButton ? undefined :
      (isEntry(this.fields.secondaryButton) ? wrap<'menuButton'>(this.fields.secondaryButton) : null)
  }

  get secondary_button(): MenuButton | null | undefined {
    return !this.fields.secondaryButton ? undefined :
      (isEntry(this.fields.secondaryButton) ? wrap<'menuButton'>(this.fields.secondaryButton) : null)
  }

  get style(): SectionHeroStyle | undefined {
    return this.fields.style
  }

  constructor(entry: ISectionHero);
  constructor(id: string, fields: ISectionHeroFields);
  constructor(entryOrId: ISectionHero | string, fields?: ISectionHeroFields) {
    super(entryOrId, 'section-hero', fields)
  }
}
