import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { CalltoAction, ICalltoAction } from "./call_to_action";
import { ITestimonial, Testimonial } from "./testimonial";

export interface ISectionIntroFields {
  internalTitle?: never;
  intro?: string;
  testimonial?: ILink<'Entry'> | ITestimonial;
  testimonialStyle?: SectionIntroTestimonialStyle;
  callToAction?: ILink<'Entry'> | ICalltoAction;
  bookmarkTitle?: string;
}

export type SectionIntroTestimonialStyle = 'default' | 'dark';

/**
 * Section: Intro
 */
export interface ISectionIntro extends IEntry<ISectionIntroFields> {
}

export function isSectionIntro(entry: IEntry<any>): entry is ISectionIntro {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-intro'
}

export class SectionIntro extends Entry<ISectionIntroFields> implements ISectionIntro {
  get intro(): string | undefined {
    return this.fields.intro
  }

  get testimonial(): Testimonial | null | undefined {
    return !this.fields.testimonial ? undefined :
      (isEntry(this.fields.testimonial) ? wrap<'testimonial'>(this.fields.testimonial) : null)
  }

  get testimonialStyle(): SectionIntroTestimonialStyle | undefined {
    return this.fields.testimonialStyle
  }

  get testimonial_style(): SectionIntroTestimonialStyle | undefined {
    return this.fields.testimonialStyle
  }

  get callToAction(): CalltoAction | null | undefined {
    return !this.fields.callToAction ? undefined :
      (isEntry(this.fields.callToAction) ? wrap<'callToAction'>(this.fields.callToAction) : null)
  }

  get call_to_action(): CalltoAction | null | undefined {
    return !this.fields.callToAction ? undefined :
      (isEntry(this.fields.callToAction) ? wrap<'callToAction'>(this.fields.callToAction) : null)
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionIntro);
  constructor(id: string, fields: ISectionIntroFields);
  constructor(entryOrId: ISectionIntro | string, fields?: ISectionIntroFields) {
    super(entryOrId, 'section-intro', fields)
  }
}
