import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { IPage, Page } from "./page";

export interface IConferenceFields {
  internalTitle?: never;
  title: string;
  abstractTitle: string;
  startDate?: string;
  thumbnail?: ILink<'Asset'> | IAsset;
  logo?: ILink<'Asset'> | IAsset;
  backgroundColor: string;
  foregroundColor: string;
  homepage: ILink<'Entry'> | IPage;
  tags?: string[];
  sequence?: number;
}

/**
 * Conference
 */
export interface IConference extends IEntry<IConferenceFields> {
}

export function isConference(entry: IEntry<any>): entry is IConference {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'conference'
}

export class Conference extends Entry<IConferenceFields> implements IConference {
  get title(): string {
    return this.fields.title
  }

  get abstractTitle(): string {
    return this.fields.abstractTitle
  }

  get abstract_title(): string {
    return this.fields.abstractTitle
  }

  get startDate(): string | undefined {
    return this.fields.startDate
  }

  get start_date(): string | undefined {
    return this.fields.startDate
  }

  get thumbnail(): Asset | null | undefined {
    return !this.fields.thumbnail ? undefined :
      (isAsset(this.fields.thumbnail) ? new Asset(this.fields.thumbnail) : null)
  }

  get logo(): Asset | null | undefined {
    return !this.fields.logo ? undefined :
      (isAsset(this.fields.logo) ? new Asset(this.fields.logo) : null)
  }

  get backgroundColor(): string {
    return this.fields.backgroundColor
  }

  get background_color(): string {
    return this.fields.backgroundColor
  }

  get foregroundColor(): string {
    return this.fields.foregroundColor
  }

  get foreground_color(): string {
    return this.fields.foregroundColor
  }

  get homepage(): Page | null {
    return (isEntry(this.fields.homepage) ? wrap<'page'>(this.fields.homepage) : null)
  }

  get tags(): string[] | undefined {
    return this.fields.tags
  }

  get sequence(): number | undefined {
    return this.fields.sequence
  }

  constructor(entry: IConference);
  constructor(id: string, fields: IConferenceFields);
  constructor(entryOrId: IConference | string, fields?: IConferenceFields) {
    super(entryOrId, 'conference', fields)
  }
}
