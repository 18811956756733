import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { CalltoAction, ICalltoAction } from "./call_to_action";
import { IMenuButton, MenuButton } from "./menu_button";
import { IMinistry, Ministry } from "./ministry";

export interface ISectionMinistryDetailsFields {
  internalTitle?: never;
  detailedDescription?: string;
  sampleMaterialsButton?: ILink<'Entry'> | IMenuButton;
  message?: ILink<'Entry'> | ICalltoAction;
  domainObject: ILink<'Entry'> | IMinistry;
  bookmarkTitle?: string;
}

/**
 * Section: Ministry Details
 * A Ministry Details section provides additional information about the ministry, in long-form text and tabular format.  Typically this appears directly following the Ministry Or Conference Header.  An optional testimonial callout or call to action can be attached.
 */
export interface ISectionMinistryDetails extends IEntry<ISectionMinistryDetailsFields> {
}

export function isSectionMinistryDetails(entry: IEntry<any>): entry is ISectionMinistryDetails {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-ministry-details'
}

export class SectionMinistryDetails extends Entry<ISectionMinistryDetailsFields> implements ISectionMinistryDetails {
  get detailedDescription(): string | undefined {
    return this.fields.detailedDescription
  }

  get detailed_description(): string | undefined {
    return this.fields.detailedDescription
  }

  get sampleMaterialsButton(): MenuButton | null | undefined {
    return !this.fields.sampleMaterialsButton ? undefined :
      (isEntry(this.fields.sampleMaterialsButton) ? wrap<'menuButton'>(this.fields.sampleMaterialsButton) : null)
  }

  get sample_materials_button(): MenuButton | null | undefined {
    return !this.fields.sampleMaterialsButton ? undefined :
      (isEntry(this.fields.sampleMaterialsButton) ? wrap<'menuButton'>(this.fields.sampleMaterialsButton) : null)
  }

  get message(): CalltoAction | null | undefined {
    return !this.fields.message ? undefined :
      (isEntry(this.fields.message) ? wrap<'callToAction'>(this.fields.message) : null)
  }

  get domainObject(): Ministry | null {
    return (isEntry(this.fields.domainObject) ? wrap<'ministry'>(this.fields.domainObject) : null)
  }

  get domain_object(): Ministry | null {
    return (isEntry(this.fields.domainObject) ? wrap<'ministry'>(this.fields.domainObject) : null)
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionMinistryDetails);
  constructor(id: string, fields: ISectionMinistryDetailsFields);
  constructor(entryOrId: ISectionMinistryDetails | string, fields?: ISectionMinistryDetailsFields) {
    super(entryOrId, 'section-ministry-details', fields)
  }
}
