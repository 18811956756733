import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { IMenu, Menu } from "./menu";
import { IMenuButton, MenuButton } from "./menu_button";
import { IPage, Page } from "./page";
import { ISectionEmailCapture, SectionEmailCapture } from "./section-email-capture";

export interface ISiteConfigFields {
  title: string;
  foreignKey: SiteConfigForeignKey;
  homepage: ILink<'Entry'> | IPage;
  shoppingCartPage?: ILink<'Entry'> | IPage;
  mainNavigation?: ILink<'Entry'> | IMenu;
  utilityMenu?: ILink<'Entry'> | IMenu;
  sideBarMenu?: ILink<'Entry'> | IMenu;
  brand?: ILink<'Entry'> | IMenuButton;
  emailHeader?: ILink<'Asset'> | IAsset;
  footerEmailCapture?: ILink<'Entry'> | ISectionEmailCapture;
  footerSocialLinks?: Array<ILink<'Entry'> | IMenuButton>;
  adminInvitationDripConfig?: any;
  invitationDripConfig?: any;
}

export type SiteConfigForeignKey = 'wm_resources_global_config';

/**
 * Site Config
 * This is the top level object for the configuration of your site.
 */
export interface ISiteConfig extends IEntry<ISiteConfigFields> {
}

export function isSiteConfig(entry: IEntry<any>): entry is ISiteConfig {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'site-config'
}

export class SiteConfig extends Entry<ISiteConfigFields> implements ISiteConfig {
  get title(): string {
    return this.fields.title
  }

  get foreignKey(): SiteConfigForeignKey {
    return this.fields.foreignKey
  }

  get foreign_key(): SiteConfigForeignKey {
    return this.fields.foreignKey
  }

  get homepage(): Page | null {
    return (isEntry(this.fields.homepage) ? wrap<'page'>(this.fields.homepage) : null)
  }

  get shoppingCartPage(): Page | null | undefined {
    return !this.fields.shoppingCartPage ? undefined :
      (isEntry(this.fields.shoppingCartPage) ? wrap<'page'>(this.fields.shoppingCartPage) : null)
  }

  get shopping_cart_page(): Page | null | undefined {
    return !this.fields.shoppingCartPage ? undefined :
      (isEntry(this.fields.shoppingCartPage) ? wrap<'page'>(this.fields.shoppingCartPage) : null)
  }

  get mainNavigation(): Menu | null | undefined {
    return !this.fields.mainNavigation ? undefined :
      (isEntry(this.fields.mainNavigation) ? wrap<'menu'>(this.fields.mainNavigation) : null)
  }

  get main_navigation(): Menu | null | undefined {
    return !this.fields.mainNavigation ? undefined :
      (isEntry(this.fields.mainNavigation) ? wrap<'menu'>(this.fields.mainNavigation) : null)
  }

  get utilityMenu(): Menu | null | undefined {
    return !this.fields.utilityMenu ? undefined :
      (isEntry(this.fields.utilityMenu) ? wrap<'menu'>(this.fields.utilityMenu) : null)
  }

  get utility_menu(): Menu | null | undefined {
    return !this.fields.utilityMenu ? undefined :
      (isEntry(this.fields.utilityMenu) ? wrap<'menu'>(this.fields.utilityMenu) : null)
  }

  get sideBarMenu(): Menu | null | undefined {
    return !this.fields.sideBarMenu ? undefined :
      (isEntry(this.fields.sideBarMenu) ? wrap<'menu'>(this.fields.sideBarMenu) : null)
  }

  get side_bar_menu(): Menu | null | undefined {
    return !this.fields.sideBarMenu ? undefined :
      (isEntry(this.fields.sideBarMenu) ? wrap<'menu'>(this.fields.sideBarMenu) : null)
  }

  get brand(): MenuButton | null | undefined {
    return !this.fields.brand ? undefined :
      (isEntry(this.fields.brand) ? wrap<'menuButton'>(this.fields.brand) : null)
  }

  get emailHeader(): Asset | null | undefined {
    return !this.fields.emailHeader ? undefined :
      (isAsset(this.fields.emailHeader) ? new Asset(this.fields.emailHeader) : null)
  }

  get email_header(): Asset | null | undefined {
    return !this.fields.emailHeader ? undefined :
      (isAsset(this.fields.emailHeader) ? new Asset(this.fields.emailHeader) : null)
  }

  get footerEmailCapture(): SectionEmailCapture | null | undefined {
    return !this.fields.footerEmailCapture ? undefined :
      (isEntry(this.fields.footerEmailCapture) ? wrap<'section-email-capture'>(this.fields.footerEmailCapture) : null)
  }

  get footer_email_capture(): SectionEmailCapture | null | undefined {
    return !this.fields.footerEmailCapture ? undefined :
      (isEntry(this.fields.footerEmailCapture) ? wrap<'section-email-capture'>(this.fields.footerEmailCapture) : null)
  }

  get footerSocialLinks(): Array<MenuButton | null> | undefined {
    return !this.fields.footerSocialLinks ? undefined :
      this.fields.footerSocialLinks.map((item) =>
        isEntry(item) ? wrap<'menuButton'>(item) : null
      )
  }

  get footer_social_links(): Array<MenuButton | null> | undefined {
    return !this.fields.footerSocialLinks ? undefined :
      this.fields.footerSocialLinks.map((item) =>
        isEntry(item) ? wrap<'menuButton'>(item) : null
      )
  }

  get adminInvitationDripConfig(): any | undefined {
    return this.fields.adminInvitationDripConfig
  }

  get admin_invitation_drip_config(): any | undefined {
    return this.fields.adminInvitationDripConfig
  }

  get invitationDripConfig(): any | undefined {
    return this.fields.invitationDripConfig
  }

  get invitation_drip_config(): any | undefined {
    return this.fields.invitationDripConfig
  }

  constructor(entry: ISiteConfig);
  constructor(id: string, fields: ISiteConfigFields);
  constructor(entryOrId: ISiteConfig | string, fields?: ISiteConfigFields) {
    super(entryOrId, 'site-config', fields)
  }
}
