import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IPartnerChurch, PartnerChurch } from "./partner_church";

export interface ISectionPartnerChurchesFields {
  internalTitle?: never;
  tag?: string;
  text?: string;
  featuredChurches?: Array<ILink<'Entry'> | IPartnerChurch>;
  bookmarkTitle?: string;
}

/**
 * Section: Partner Churches
 * A Partner Churches section is used to display the logos of the partner churches participating in a particular Watermark ministry.
 */
export interface ISectionPartnerChurches extends IEntry<ISectionPartnerChurchesFields> {
}

export function isSectionPartnerChurches(entry: IEntry<any>): entry is ISectionPartnerChurches {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-partner-churches'
}

export class SectionPartnerChurches extends Entry<ISectionPartnerChurchesFields> implements ISectionPartnerChurches {
  get tag(): string | undefined {
    return this.fields.tag
  }

  get text(): string | undefined {
    return this.fields.text
  }

  get featuredChurches(): Array<PartnerChurch | null> | undefined {
    return !this.fields.featuredChurches ? undefined :
      this.fields.featuredChurches.map((item) =>
        isEntry(item) ? wrap<'partnerChurch'>(item) : null
      )
  }

  get featured_churches(): Array<PartnerChurch | null> | undefined {
    return !this.fields.featuredChurches ? undefined :
      this.fields.featuredChurches.map((item) =>
        isEntry(item) ? wrap<'partnerChurch'>(item) : null
      )
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionPartnerChurches);
  constructor(id: string, fields: ISectionPartnerChurchesFields);
  constructor(entryOrId: ISectionPartnerChurches | string, fields?: ISectionPartnerChurchesFields) {
    super(entryOrId, 'section-partner-churches', fields)
  }
}
