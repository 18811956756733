import React, { useState } from "react"
import IUser from "../../lib/models/user"
import { IMinistry } from "../../lib/contentful/generated"
import { CountryDropdown } from 'react-country-region-selector'
import { useCreateInvitation } from '../../lib/hooks/useCreateInvitation'
import { StateSelect } from "./state-select"

interface IProps {
  user: IUser
  securedApiKey: string
  ministry: IMinistry
  securityLevelId: number
  provisionalAccountId: number
}

interface IFormData {
  church_name: string
  state: string
  city: string
  country: string
}

const regionLabels = {
  'Canada': 'Province',
  'United States': 'State',
}

const fieldProps = {
  required: '',
}

export function NewChurchForm(props: IProps) {
  const [formData, setFormData] = useState<IFormData>({
    church_name: '',
    state: '',
    city: '',
    country: 'United States',
  })

  const { user, ministry, provisionalAccountId } = props
  const inviteProps = {
    user: user,
    ministryId: ministry.sys.id,
    accountId: provisionalAccountId,
    securityLevelId: props.securityLevelId,
  }

  const [{data, error, wait}, createInvitation] = useCreateInvitation()

  function handleFormDataChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    setFormData({ ...formData, [event.currentTarget.name]: event.currentTarget.value })
  }

  if (data) {
    return <>
      <h4 className="self-service__form-question">
        Please check your email for a verification notice.
      </h4>
      <h4 className="self-service__form-question" style={{ padding: 0 }}>
        Click the link in the email message to verify your email address <strong>{data.invitation.email}</strong>.
      </h4>
      <div className="account-search__submit" style={{ textAlign: 'center', }}>
        <a className='btn btn-primary send-email' style={{ marginBottom: '8px' }} href={`${window.location.href}?assertNeverGotEmail=true`}>
          Resend Verification
        </a>
        <p>Didn&apos;t receive your verification email? <a href='/contact'>Contact us for help</a></p>
      </div>
    </>
  }

  return (<>
    <h4 className="self-service__form-question">
      Welcome, {user.first_name}. Just one more question. What is your home church?
    </h4>
    <form className="self-serfice__form-container" onSubmit={(event) => {
      event.preventDefault()

      createInvitation(formData, inviteProps)
    }}>
      <label>Church Name</label>
      <input
        type='text'
        name='church_name'
        minLength={3}
        value={formData.church_name}
        onChange={handleFormDataChange}
      />
      <label>Country</label>
      <div className="select-field">
        <CountryDropdown
          onChange={val => {setFormData({ ...formData, country: val })}}
          name='country'
          value={formData.country}
          priorityOptions={['US', 'CA']}
          { ...fieldProps } />
      </div>
      <div className='account-search__church-location d-flex gap-1'>
        <div className='account-search__church-location-city'>

          <label htmlFor='church-city'>City</label>
          <input
            id='church-city'
            name='city'
            minLength={3}
            value={formData.city}
            type='text'
            onChange={handleFormDataChange}
          />
        </div>
        <div className='account-search__church-location-state'>
          <label htmlFor=''>State/Province</label>
          {stateZipApplies() ?
            <StateSelect
              stateZipApplies={true}
              country={formData.country}
              state={formData.state}
              label={regionLabels[formData.country]}
              onChange={val => { setFormData({ ...formData, state: val }) }}
            /> : <StateSelect
              stateZipApplies={false}
              country={formData.country}
              state={formData.state}
              label={regionLabels[formData.country]}
              onChange={val => { setFormData({ ...formData, state: val }) }}
            />
          }
        </div>
      </div>
      {error && <div className="alert alert-danger" role="alert">
        Something went wrong:
        {error.message || error.toString()}
      </div>}
      {wait ?
        <button className="btn btn-primary send-email" disabled={true}>Signing In...</button> :
        <button className="btn btn-primary send-email" disabled={!formData.church_name || !formData.city || !formData.state || !formData.country}>Sign In</button>}
      <a className='btn btn-link ml-auto' href={`/users/sign_out?origin=${window.location.href}`}
        rel='nofollow'
        data-method="delete">
        Go Back
      </a>
    </form>
    </>)

  function stateZipApplies(): boolean {
    return ['United States', 'Canada'].indexOf(formData.country) !== -1
  }
}
