import { Map, Marker as GoogleMapsReactMarker } from '@watermarkchurch/google-maps-react';
import * as React from 'react';
import { connectHits } from 'react-instantsearch-core';
import { connectCurrentMapBounds } from './connectors/connectCurrentMapBounds';
import mapStyles from './map-pane/map-style-config';
const centerUS = { lat: 39.82, lng: -96.1 };
export const DefaultLocationMarker = ({ selectedLocation, location, ...props }) => {
    // dim opacity if another location was selected
    const opacity = selectedLocation && selectedLocation.id != location.objectID ? 0.6 : 1.0;
    return React.createElement(GoogleMapsReactMarker, { cursor: "pointer", opacity: opacity, ...props });
};
class MapContainer extends React.Component {
    static defaultProps = {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        initialCenter: centerUS,
        zoom: 4,
        maxZoom: 16,
        minZoom: 4,
        styles: mapStyles,
    };
    render() {
        const { onLocationSelect, bounds, ...props } = this.props;
        const hits = this.props.hits.filter((x) => validGeoloc(x._geoloc));
        return (React.createElement(Map, { ...props, bounds: bounds, onReady: (_, map) => map.fitBounds(bounds) }, this.renderMarkers(...hits)));
    }
    renderMarkers(...hits) {
        const Marker = this.props.Marker || DefaultLocationMarker;
        return hits.map((hit) => {
            return React.createElement(Marker, { key: hit.objectID, onClick: () => this.props.onLocationSelect(hit), position: hit._geoloc, title: hit.title, location: hit, selectedLocation: this.props.selectedLocation });
        });
    }
}
export default connectCurrentMapBounds(connectHits(MapContainer));
function validGeoloc(geoloc) {
    return geoloc && (geoloc.lat != 0 || geoloc.lng != 0);
}
