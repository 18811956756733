import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { Faq, IFaq } from "./faq";
import { IMenuButton, MenuButton } from "./menu_button";

export interface ISectionFaqFields {
  internalTitle?: never;
  name: string;
  text?: string;
  actionButton?: ILink<'Entry'> | IMenuButton;
  faqs?: Array<ILink<'Entry'> | IFaq>;
  style?: SectionFaqStyle;
  bookmarkTitle?: string;
}

export type SectionFaqStyle = 'default' | 'overlaps-previous';

/**
 * Section: FAQ
 * A FAQ Section contains a number of question and answer pairs (FAQs) curated by the administrator.
 */
export interface ISectionFaq extends IEntry<ISectionFaqFields> {
}

export function isSectionFaq(entry: IEntry<any>): entry is ISectionFaq {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-faq'
}

export class SectionFaq extends Entry<ISectionFaqFields> implements ISectionFaq {
  get name(): string {
    return this.fields.name
  }

  get text(): string | undefined {
    return this.fields.text
  }

  get actionButton(): MenuButton | null | undefined {
    return !this.fields.actionButton ? undefined :
      (isEntry(this.fields.actionButton) ? wrap<'menuButton'>(this.fields.actionButton) : null)
  }

  get action_button(): MenuButton | null | undefined {
    return !this.fields.actionButton ? undefined :
      (isEntry(this.fields.actionButton) ? wrap<'menuButton'>(this.fields.actionButton) : null)
  }

  get faqs(): Array<Faq | null> | undefined {
    return !this.fields.faqs ? undefined :
      this.fields.faqs.map((item) =>
        isEntry(item) ? wrap<'faq'>(item) : null
      )
  }

  get style(): SectionFaqStyle | undefined {
    return this.fields.style
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionFaq);
  constructor(id: string, fields: ISectionFaqFields);
  constructor(entryOrId: ISectionFaq | string, fields?: ISectionFaqFields) {
    super(entryOrId, 'section-faq', fields)
  }
}
