import { Asset, Entry, IAsset, IEntry, ILink, isAsset } from "../base";

export interface IPageMetadataFields {
  internalTitle?: string;
  metaDescription: string;
  canonicalLink?: string;
  metaKeywords?: string;
  metaFlag?: PageMetadataMetaFlag[];
  structuredData?: any;
  openGraphImage?: ILink<'Asset'> | IAsset;
  alternateLinks?: any;
}

export type PageMetadataMetaFlag = 'no-follow' | 'no-index';

/**
 * Page Metadata
 */
export interface IPageMetadata extends IEntry<IPageMetadataFields> {
}

export function isPageMetadata(entry: IEntry<any>): entry is IPageMetadata {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'pageMetadata'
}

export class PageMetadata extends Entry<IPageMetadataFields> implements IPageMetadata {
  get internalTitle(): string | undefined {
    return this.fields.internalTitle
  }

  get internal_title(): string | undefined {
    return this.fields.internalTitle
  }

  get metaDescription(): string {
    return this.fields.metaDescription
  }

  get meta_description(): string {
    return this.fields.metaDescription
  }

  get canonicalLink(): string | undefined {
    return this.fields.canonicalLink
  }

  get canonical_link(): string | undefined {
    return this.fields.canonicalLink
  }

  get metaKeywords(): string | undefined {
    return this.fields.metaKeywords
  }

  get meta_keywords(): string | undefined {
    return this.fields.metaKeywords
  }

  get metaFlag(): PageMetadataMetaFlag[] | undefined {
    return this.fields.metaFlag
  }

  get meta_flag(): PageMetadataMetaFlag[] | undefined {
    return this.fields.metaFlag
  }

  get structuredData(): any | undefined {
    return this.fields.structuredData
  }

  get structured_data(): any | undefined {
    return this.fields.structuredData
  }

  get openGraphImage(): Asset | null | undefined {
    return !this.fields.openGraphImage ? undefined :
      (isAsset(this.fields.openGraphImage) ? new Asset(this.fields.openGraphImage) : null)
  }

  get open_graph_image(): Asset | null | undefined {
    return !this.fields.openGraphImage ? undefined :
      (isAsset(this.fields.openGraphImage) ? new Asset(this.fields.openGraphImage) : null)
  }

  get alternateLinks(): any | undefined {
    return this.fields.alternateLinks
  }

  get alternate_links(): any | undefined {
    return this.fields.alternateLinks
  }

  constructor(entry: IPageMetadata);
  constructor(id: string, fields: IPageMetadataFields);
  constructor(entryOrId: IPageMetadata | string, fields?: IPageMetadataFields) {
    super(entryOrId, 'pageMetadata', fields)
  }
}
