import { createConnector } from 'react-instantsearch-core';
import { present, isTooBig, milesToMeters, parsePosition } from '../utils';
import { pick } from '../utils/pick';
const MaxSearchRadiusMeters = Math.ceil(milesToMeters(50)); // 50 miles
export const MapRefinementKeys = [
    'selectedLocation',
    'selectedPlace',
    'includeUserLocationInBounds',
    'expandSearch',
];
/**
 * This function connects the Map "Refinement" to Algolia, which is how the user can select a location to search around.
 * Calling the refine method here will execute a new search.
 */
export const connectMapRefinement = createConnector({
    displayName: 'MapBoundsRefinement',
    getProvidedProps(props, searchState, searchResults) {
        const mapBounds = Object.assign({}, searchState.mapBounds);
        let userLocation;
        let maxSearchRadiusMeters;
        let placeFacetValue;
        // Mimic the logic inside getSearchParameters, setting appropriate values on currentRefinement
        if (!hasMapRefinements(searchState)) {
            const results = searchResults && searchResults.results;
            // no current location given, see if Algolia parsed it via our IP
            userLocation = results && present(results.aroundLatLng) && parsePosition(results.aroundLatLng) || null;
            if (userLocation) {
                return {
                    currentRefinement: mapBounds,
                    userLocation,
                };
            }
        }
        const { selectedPlace, expandSearch } = mapBounds;
        if (expandSearch && selectedPlace?.location) {
            userLocation = selectedPlace.location;
            return {
                currentRefinement: mapBounds,
                userLocation,
            };
        }
        else if (isTooBig(selectedPlace?.types, selectedPlace?.viewport)) {
            placeFacetValue = selectedPlace.name;
        }
        else if (selectedPlace?.location) {
            userLocation = selectedPlace.location;
            maxSearchRadiusMeters = MaxSearchRadiusMeters;
        }
        return {
            currentRefinement: mapBounds,
            userLocation,
            maxSearchRadiusMeters,
            placeFacetValue,
        };
    },
    refine(props, searchState, nextRefinement) {
        return {
            ...searchState,
            mapBounds: {
                ...searchState.mapBounds,
                ...pick(nextRefinement, MapRefinementKeys),
            },
        };
    },
    getSearchParameters(searchParameters, props, searchState) {
        if (!hasMapRefinements(searchState)) {
            // search around your current location as determined by Algolia
            searchParameters.aroundLatLngViaIP = true;
            searchParameters.aroundRadius = 'all';
            return searchParameters;
        }
        const { selectedLocation, selectedPlace, expandSearch } = searchState.mapBounds;
        if (selectedLocation) {
            // We need to ensure that the selectedLocation is present in the hits
            // so that the selectedLocation is displayed in the search results
            if (selectedLocation.lat && selectedLocation.lng) {
                searchParameters.aroundLatLng = `${selectedLocation.lat}, ${selectedLocation.lng}`;
                searchParameters.aroundLatLngViaIP = false;
                searchParameters.aroundRadius = 1000;
            }
            if (selectedLocation.title) {
                searchParameters.query = selectedLocation.title;
            }
            return searchParameters;
        }
        if (expandSearch && selectedPlace?.location) {
            // expand out from current location to all hits
            const loc = selectedPlace.location;
            searchParameters.aroundLatLng = `${loc.lat}, ${loc.lng}`;
            searchParameters.aroundRadius = 'all';
        }
        else if (isTooBig(selectedPlace?.types, selectedPlace?.viewport)) {
            // search within facet of place name
            searchParameters = searchParameters.addDisjunctiveFacet('places')
                .addDisjunctiveFacetRefinement('places', selectedPlace.name);
        }
        else if (selectedPlace?.location) {
            // search in 50 mile radius from selected place (ex. a personal address)
            const loc = selectedPlace.location;
            searchParameters.aroundLatLng = `${loc.lat}, ${loc.lng}`;
            searchParameters.aroundRadius = MaxSearchRadiusMeters;
        }
        return searchParameters;
    },
});
function hasMapRefinements(searchState) {
    return !!(searchState.mapBounds && searchState.mapBounds.selectedPlace);
}
