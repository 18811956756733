import * as React from 'react';
import { present } from '.';
import { renderAddress, renderMeetingTime, renderWebsite } from './utils';
import { connectCurrentSelectedLocation } from './connectors/connectCurrentSelectedLocation';
export class LocationDetailPane extends React.Component {
    render() {
        const location = this.props.selectedLocation;
        return (React.createElement("div", { className: "location-details" },
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__group" },
                        React.createElement("h3", { className: "location-details__title" }, location.title)))),
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__meta" },
                        React.createElement("i", { className: "material-icons" }, "schedule"),
                        React.createElement("h6", { className: "label" }, "Meeting Info"),
                        React.createElement("p", null,
                            renderMeetingTime(location, this.props.translate),
                            React.createElement("br", null),
                            this.props.translate(['locationMap', 'childcare', location.hasChildcare ? 'yes' : 'no'], { defaultValue: `Childcare ${location.hasChildcare ? 'is' : 'is not'} available` }))))),
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__meta" },
                        React.createElement("i", { className: "material-icons" }, "location_on"),
                        React.createElement("h6", { className: "label" }, "Address"),
                        React.createElement("a", { href: `https://www.google.com/maps/place/${encodeURIComponent(renderAddress(location.address))}`, target: "_blank", rel: "noreferrer" }, renderAddress(location.address))))),
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__meta" },
                        React.createElement("i", { className: "material-icons" }, "computer"),
                        React.createElement("h6", { className: "label" }, "Website"),
                        React.createElement("a", { href: location.website, target: "_blank", rel: "noreferrer" }, renderWebsite(location.website))))),
            location.contact &&
                React.createElement("div", { className: "row no-gutters" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "location-details__meta" },
                            React.createElement("i", { className: "material-icons" }, "account_circle"),
                            React.createElement("h6", { className: "label" }, "Contact Info"),
                            joinChildren([
                                location.contact.name &&
                                    React.createElement("span", null, location.contact.name),
                                location.contact.phone &&
                                    React.createElement("a", { href: `tel:${location.contact.phone}` }, location.contact.phone),
                                location.contact.email &&
                                    React.createElement("a", { href: `mailto:${location.contact.email}` }, location.contact.email)
                            ].filter(present), React.createElement("br", null))))),
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("p", null, location.description)))));
    }
}
function joinChildren(items, separator) {
    return items.reduce((items, item, index) => {
        if (index > 0) {
            return [...items, separator, item];
        }
        return [...items, item];
    }, []);
}
const ConnectedLocationDetailPane = connectCurrentSelectedLocation(LocationDetailPane);
export default ConnectedLocationDetailPane;
