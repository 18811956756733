import React from "react"
import { connectHits, Hit } from "react-instantsearch/connectors"
import IAlgoliaAccount from "../../lib/models/algolia-account"
import { IAddress } from "../address-form/types"

interface IProps {
  hits: Array<Hit<IAlgoliaAccount>>
  onClick: { (hit: Hit<IAlgoliaAccount> | null): (event: React.MouseEvent<HTMLButtonElement>) => void }
  reassign?: boolean
}

class AccountHits extends React.Component<IProps> {
  public render() {
    const { hits, onClick, reassign } = this.props

    const accountLocation = (location: IAddress) => {
      let locationString = ''
      if (location.country !== 'United States') {
        locationString = `${location.city}`
        if(location.state) locationString += `, ${location.state}`
        locationString += `, ${location.country}`
      } else {
        locationString = `${location.city}, ${location.state}`
      }

      return locationString
    }

    return (
      <>
        {
          hits.map((hit) => (
            <div className='account-list-item' key={hit.objectID} style={{ margin: '0 12px' }}>
              <button className="dropdown-item" onClick={onClick(hit)}>
                {hit.name}
                {hit.location && (
                  <span className="account-location">{accountLocation(hit.location)}</span>)}
              </button>
            </div>
          ))
        }
        {
          !reassign && hits.length < 10 &&  (
            <div className='account-list-item' style={{ margin: '0 12px' }}>
              <button className="dropdown-item unlisted" onClick={onClick(null)}>
                My church isn&apos;t listed here.
              </button>
            </div>
          )
        }
      </>
    )
  }
}


export const ConnectedAccountList = connectHits(AccountHits)
