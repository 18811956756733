import React, { useEffect } from "react"
import { connectSearchBox } from 'react-instantsearch-dom'

interface IProps {
  currentRefinement: string
  refine: (value: string) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  name: string
}

function CustomSearchBox(props: IProps) {
  const { currentRefinement, refine, onChange, name, onClick, onBlur } = props
  const refInput = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (refInput.current) {
      refInput.current.focus()
    }
  })

  return (
    <input
      ref={refInput}
      type="search"
      minLength={3}
      className="search-box"
      value={name || currentRefinement}
      onClick={onClick}
      onBlur={onBlur}
      onChange={event => {
        onChange(event)
        refine(event.currentTarget.value)
      }}
      placeholder="Search for your church"
    />
  )
}

export const ConnectedSearchBox = connectSearchBox(CustomSearchBox)