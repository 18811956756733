import * as React from 'react'
import { AlgoliaSearch } from '@watermarkchurch/react-instantsearch-components'
import WCC from 'wcc'

interface IInstantSearchProps {
  indexName: string
  widgetName: string
  securedApiKey?: string
}

export default class extends React.Component<IInstantSearchProps> {
  public render() {
    const { widgetName, securedApiKey, ...props } = this.props
    const algoliaKey = securedApiKey || WCC.WMR_CONSTANTS.ALGOLIA_SEARCH_API_KEY

    return (
      <AlgoliaSearch
        apiKey={algoliaKey}
        appId={WCC.WMR_CONSTANTS.ALGOLIA_APP_ID}
        widgetName={`${widgetName}-widget`}
        {...props}
      >
        {this.props.children}
      </AlgoliaSearch>
    )
  }
}
