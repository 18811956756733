import { ForbiddenError } from '@casl/ability'
import jQuery from 'jquery'

function authenticityToken(): string | undefined {
  const elem = jQuery('meta[name="csrf-token"]')
  return elem && elem.attr('content')
}

export async function localFetch(url: string, init?: RequestInit) {
  init = init || {}
  const headers = init.headers
  delete(init.headers)

  const response = await fetch(
    url,
    {
      headers: Object.assign({
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticityToken(),
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, headers),
      credentials: 'same-origin',
      ...init,
    },
  )

  if (response.status == 401) {
    throw new ServerForbiddenError(response)
  }
  return response
}

class ServerForbiddenError extends ForbiddenError {
  constructor(public readonly response: Response) {
    super('You are not authorized to perform this action.')
  }
}
