import { Entry, IEntry } from "../base";

export interface IEmailFields {
  name: string;
  fromEmail?: string;
  fromName?: string;
  subject?: string;
  previewText?: string;
  content: string;
  disclaimer?: string;
}

/**
 * Email
 */
export interface IEmail extends IEntry<IEmailFields> {
}

export function isEmail(entry: IEntry<any>): entry is IEmail {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'email'
}

export class Email extends Entry<IEmailFields> implements IEmail {
  get name(): string {
    return this.fields.name
  }

  get fromEmail(): string | undefined {
    return this.fields.fromEmail
  }

  get from_email(): string | undefined {
    return this.fields.fromEmail
  }

  get fromName(): string | undefined {
    return this.fields.fromName
  }

  get from_name(): string | undefined {
    return this.fields.fromName
  }

  get subject(): string | undefined {
    return this.fields.subject
  }

  get previewText(): string | undefined {
    return this.fields.previewText
  }

  get preview_text(): string | undefined {
    return this.fields.previewText
  }

  get content(): string {
    return this.fields.content
  }

  get disclaimer(): string | undefined {
    return this.fields.disclaimer
  }

  constructor(entry: IEmail);
  constructor(id: string, fields: IEmailFields);
  constructor(entryOrId: IEmail | string, fields?: IEmailFields) {
    super(entryOrId, 'email', fields)
  }
}
