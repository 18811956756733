import { Asset, Entry, IAsset, IEntry, ILink, isAsset } from "../base";

export interface ICartButtonFields {
  internalTitle?: never;
  text: string;
  icon?: ILink<'Asset'> | IAsset;
  ionIcon?: string;
  style?: CartButtonStyle;
  size?: CartButtonSize;
}

export type CartButtonStyle = 'oval-border';
export type CartButtonSize = 'large' | 'medium' | 'small';

/**
 * Cart Button
 */
export interface ICartButton extends IEntry<ICartButtonFields> {
}

export function isCartButton(entry: IEntry<any>): entry is ICartButton {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'cartButton'
}

export class CartButton extends Entry<ICartButtonFields> implements ICartButton {
  get text(): string {
    return this.fields.text
  }

  get icon(): Asset | null | undefined {
    return !this.fields.icon ? undefined :
      (isAsset(this.fields.icon) ? new Asset(this.fields.icon) : null)
  }

  get ionIcon(): string | undefined {
    return this.fields.ionIcon
  }

  get ion_icon(): string | undefined {
    return this.fields.ionIcon
  }

  get style(): CartButtonStyle | undefined {
    return this.fields.style
  }

  get size(): CartButtonSize | undefined {
    return this.fields.size
  }

  constructor(entry: ICartButton);
  constructor(id: string, fields: ICartButtonFields);
  constructor(entryOrId: ICartButton | string, fields?: ICartButtonFields) {
    super(entryOrId, 'cartButton', fields)
  }
}
