import * as React from 'react';
import { connectHits } from 'react-instantsearch-core';
import { connectMapRefinement } from './connectors/connectMapRefinement';
import { ConnectedExpandSearch } from './list-pane/expand-search';
import { DefaultListItem } from './list-pane/list-item';
import ConnectedStats from './list-pane/stats';
export class List extends React.Component {
    render() {
        const { hits, onLocationSelect, } = this.props;
        return (React.createElement("div", { className: "overflow-list" },
            React.createElement(ConnectedStats, { translate: this.props.translate }),
            hits && hits.map((hit) => React.createElement(ConnectedListItemWrapper, { key: hit.objectID, hit: hit, google: this.props.google, translate: this.props.translate, onLocationSelect: onLocationSelect, ListItem: this.props.ListItem || DefaultListItem })),
            React.createElement(ConnectedExpandSearch, null)));
    }
}
const ConnectedListItemWrapper = connectMapRefinement(({ onLocationSelect, selectedLocation, ListItem, ...props }) => {
    const onClick = (e) => {
        e.preventDefault();
        onLocationSelect(props.hit);
    };
    const active = selectedLocation && selectedLocation.id == props.hit.objectID || false;
    return React.createElement("a", { className: `churches-list__item__link ${props.active ? 'active' : 'not-active'}`, onClick: onClick },
        React.createElement(ListItem, { active: active, ...props }));
});
const ListPane = connectHits(List);
export default ListPane;
