import { createConnector } from 'react-instantsearch-core';
/**
 * This function "Gets" the currently selected location off the search state context.
 * This is like getting a value off of a React.Context, but it's the Algolia way of doing it.
 * We need this because a number of components need to know what the currently selected location is, but don't need to change it.
 */
export const connectCurrentSelectedLocation = createConnector({
    displayName: 'BoundsProvider',
    getProvidedProps(props, searchState, { results }, metadata) {
        const { selectedLocation, } = searchState.mapBounds || { expandSearch: false };
        const hit = selectedLocation &&
            results?.hits?.find((hit) => hit.objectID === selectedLocation.id &&
                hit.isVirtual == selectedLocation.isVirtual) || null;
        return {
            selectedLocation: hit
        };
    },
});
