import { Asset, Entry, IAsset, IEntry, ILink, isAsset } from "../base";

export interface IPartnerChurchFields {
  internalTitle?: never;
  name: string;
  logo?: ILink<'Asset'> | IAsset;
}

/**
 * Partner Church
 * A Partner Church represents a church which offers one or more Watermark ministries or classes.
 */
export interface IPartnerChurch extends IEntry<IPartnerChurchFields> {
}

export function isPartnerChurch(entry: IEntry<any>): entry is IPartnerChurch {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'partnerChurch'
}

export class PartnerChurch extends Entry<IPartnerChurchFields> implements IPartnerChurch {
  get name(): string {
    return this.fields.name
  }

  get logo(): Asset | null | undefined {
    return !this.fields.logo ? undefined :
      (isAsset(this.fields.logo) ? new Asset(this.fields.logo) : null)
  }

  constructor(entry: IPartnerChurch);
  constructor(id: string, fields: IPartnerChurchFields);
  constructor(entryOrId: IPartnerChurch | string, fields?: IPartnerChurchFields) {
    super(entryOrId, 'partnerChurch', fields)
  }
}
