import * as React from 'react';
import { connectMapRefinement } from '../connectors/connectMapRefinement';
export const ConnectedExpandSearch = connectMapRefinement(({ refine, currentRefinement }) => {
    if ((!currentRefinement.selectedPlace) || currentRefinement.expandSearch) {
        return null;
    }
    return React.createElement("a", { className: "react-trigger", href: "#", onClick: (e) => {
            e.preventDefault();
            refine({ expandSearch: true });
        } }, "Expand Search");
});
