import * as React from 'react'
import WCC, { ICurrentUser, IWCC } from 'wcc'
import { Omit } from '../types'

const PageContext = React.createContext(WCC)

/**
 * This react component sets the PageContext for all it's children to the value
 * of the WCC global.  Individual properties can be overridden by setting them
 * on this component.
 * @param props the properties to override in the context
 */
export const PageContextProvider: React.ComponentType<Partial<IWCC>> = (props) => (
  <PageContext.Provider value={Object.assign({}, WCC, props)}>
    {props.children}
  </PageContext.Provider>
)

interface ICurrentUserProvided { user: ICurrentUser }
/**
 * Wraps a React component to automagically set the 'user' property to the
 * current user retrieved from the page context.
 */
export function connectCurrentUser<TProps extends ICurrentUserProvided>(
  WrappedComponent: React.ComponentType<TProps>,
): React.ComponentType<Omit<TProps, ICurrentUserProvided>> {
  return (props) => (
    <PageContext.Consumer>
      { (context) => <WrappedComponent {...props as TProps} user={context.currentUser} /> }
    </PageContext.Consumer>
  )
}
