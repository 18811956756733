import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { ISectionEmailCapture, SectionEmailCapture } from "./section-email-capture";

export interface ISectionVideoHighlightFields {
  internalTitle?: never;
  tag?: string;
  title: string;
  subtext?: string;
  embedCode: string;
  emailCapture?: ILink<'Entry'> | ISectionEmailCapture;
  styles?: SectionVideoHighlightStyle[];
  bookmarkTitle?: string;
}

export type SectionVideoHighlightStyle = 'video-left' | 'video-right' | 'dark-bg' | 'light-bg' | 'white-bg' | 'gray-bg';

/**
 * Section: Video Highlight
 * Highlight a video with some text
 */
export interface ISectionVideoHighlight extends IEntry<ISectionVideoHighlightFields> {
}

export function isSectionVideoHighlight(entry: IEntry<any>): entry is ISectionVideoHighlight {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-video-highlight'
}

export class SectionVideoHighlight extends Entry<ISectionVideoHighlightFields> implements ISectionVideoHighlight {
  get tag(): string | undefined {
    return this.fields.tag
  }

  get title(): string {
    return this.fields.title
  }

  get subtext(): string | undefined {
    return this.fields.subtext
  }

  get embedCode(): string {
    return this.fields.embedCode
  }

  get embed_code(): string {
    return this.fields.embedCode
  }

  get emailCapture(): SectionEmailCapture | null | undefined {
    return !this.fields.emailCapture ? undefined :
      (isEntry(this.fields.emailCapture) ? wrap<'section-email-capture'>(this.fields.emailCapture) : null)
  }

  get email_capture(): SectionEmailCapture | null | undefined {
    return !this.fields.emailCapture ? undefined :
      (isEntry(this.fields.emailCapture) ? wrap<'section-email-capture'>(this.fields.emailCapture) : null)
  }

  get styles(): SectionVideoHighlightStyle[] | undefined {
    return this.fields.styles
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionVideoHighlight);
  constructor(id: string, fields: ISectionVideoHighlightFields);
  constructor(entryOrId: ISectionVideoHighlight | string, fields?: ISectionVideoHighlightFields) {
    super(entryOrId, 'section-video-highlight', fields)
  }
}
