import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { Conference, IConference } from "./conference";
import { IMinistry, Ministry } from "./ministry";
import { IPage, Page } from "./page";

export interface IResourceFields {
  internalTitle: string;
  title: string;
  icon?: ResourceIcon;
  tags?: string[];
  blurb?: string;
  downloadAsset?: ILink<'Asset'> | IAsset;
  downloadExternalAssetUrl?: string;
  viewAsset?: ILink<'Asset'> | IAsset;
  viewExternalAssetUrl?: string;
  viewPage?: ILink<'Entry'> | IPage;
  securityLevel: ResourceSecurityLevel;
  domainObject: ILink<'Entry'> | ResourceDomainObject;
}

export type ResourceIcon = 'document' | 'video' | 'article' | 'asset' | 'audio' | 'image';
export type ResourceSecurityLevel = 'Anonymous' | 'Mentor' | 'Lay Leader' | 'Church Admin' | 'WM Admin';
export type ResourceDomainObject = IConference | IMinistry;
export type ResourceDomainObjectClass = Conference | Ministry;

/**
 * Resource
 */
export interface IResource extends IEntry<IResourceFields> {
}

export function isResource(entry: IEntry<any>): entry is IResource {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'resource'
}

export class Resource extends Entry<IResourceFields> implements IResource {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get title(): string {
    return this.fields.title
  }

  get icon(): ResourceIcon | undefined {
    return this.fields.icon
  }

  get tags(): string[] | undefined {
    return this.fields.tags
  }

  get blurb(): string | undefined {
    return this.fields.blurb
  }

  get downloadAsset(): Asset | null | undefined {
    return !this.fields.downloadAsset ? undefined :
      (isAsset(this.fields.downloadAsset) ? new Asset(this.fields.downloadAsset) : null)
  }

  get download_asset(): Asset | null | undefined {
    return !this.fields.downloadAsset ? undefined :
      (isAsset(this.fields.downloadAsset) ? new Asset(this.fields.downloadAsset) : null)
  }

  get downloadExternalAssetUrl(): string | undefined {
    return this.fields.downloadExternalAssetUrl
  }

  get download_external_asset_url(): string | undefined {
    return this.fields.downloadExternalAssetUrl
  }

  get viewAsset(): Asset | null | undefined {
    return !this.fields.viewAsset ? undefined :
      (isAsset(this.fields.viewAsset) ? new Asset(this.fields.viewAsset) : null)
  }

  get view_asset(): Asset | null | undefined {
    return !this.fields.viewAsset ? undefined :
      (isAsset(this.fields.viewAsset) ? new Asset(this.fields.viewAsset) : null)
  }

  get viewExternalAssetUrl(): string | undefined {
    return this.fields.viewExternalAssetUrl
  }

  get view_external_asset_url(): string | undefined {
    return this.fields.viewExternalAssetUrl
  }

  get viewPage(): Page | null | undefined {
    return !this.fields.viewPage ? undefined :
      (isEntry(this.fields.viewPage) ? wrap<'page'>(this.fields.viewPage) : null)
  }

  get view_page(): Page | null | undefined {
    return !this.fields.viewPage ? undefined :
      (isEntry(this.fields.viewPage) ? wrap<'page'>(this.fields.viewPage) : null)
  }

  get securityLevel(): ResourceSecurityLevel {
    return this.fields.securityLevel
  }

  get security_level(): ResourceSecurityLevel {
    return this.fields.securityLevel
  }

  get domainObject(): ResourceDomainObjectClass | null {
    return (isEntry(this.fields.domainObject) ? wrap<'conference' | 'ministry'>(this.fields.domainObject) : null)
  }

  get domain_object(): ResourceDomainObjectClass | null {
    return (isEntry(this.fields.domainObject) ? wrap<'conference' | 'ministry'>(this.fields.domainObject) : null)
  }

  constructor(entry: IResource);
  constructor(id: string, fields: IResourceFields);
  constructor(entryOrId: IResource | string, fields?: IResourceFields) {
    super(entryOrId, 'resource', fields)
  }
}
