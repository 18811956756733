import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IMenuButton, MenuButton } from "./menu_button";
import { IPage, Page } from "./page";

export interface ISectionFeaturedItemsFields {
  internalTitle?: never;
  title: string;
  link: ILink<'Entry'> | IMenuButton;
  items?: Array<ILink<'Entry'> | IPage>;
}

/**
 * Section: Featured Items
 * Display a row of cards
 */
export interface ISectionFeaturedItems extends IEntry<ISectionFeaturedItemsFields> {
}

export function isSectionFeaturedItems(entry: IEntry<any>): entry is ISectionFeaturedItems {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-featured-items'
}

export class SectionFeaturedItems extends Entry<ISectionFeaturedItemsFields> implements ISectionFeaturedItems {
  get title(): string {
    return this.fields.title
  }

  get link(): MenuButton | null {
    return (isEntry(this.fields.link) ? wrap<'menuButton'>(this.fields.link) : null)
  }

  get items(): Array<Page | null> | undefined {
    return !this.fields.items ? undefined :
      this.fields.items.map((item) =>
        isEntry(item) ? wrap<'page'>(item) : null
      )
  }

  constructor(entry: ISectionFeaturedItems);
  constructor(id: string, fields: ISectionFeaturedItemsFields);
  constructor(entryOrId: ISectionFeaturedItems | string, fields?: ISectionFeaturedItemsFields) {
    super(entryOrId, 'section-featured-items', fields)
  }
}
