import * as React from 'react'

interface IIonicButtonProps {
  buttonText?: string
  iconClass?: string
}

export default class IonicButton extends React.Component<IIonicButtonProps & React.HTMLProps<HTMLAnchorElement>> {
  public static defaultProps = {
    buttonText: 'View',
    className: 'button__icon-circle',
    iconClass: 'ion-eye',
    style: {
      marginRight: '5px',
      marginLeft: '5px',
    },
  }

  public render() {
    const { iconClass, buttonText, ...props } = this.props

    return (
      <a {...props}>
        <i className={`icon ${iconClass}`} />
        <span className="visually-hidden">{this.props.buttonText}</span>
      </a>
    )
  }
}
