import * as React from 'react'
import Modal from 'react-modal'

import { IMinistry, Ministry } from '../../lib/contentful/generated'

import { IAsset } from '../../lib/contentful'
import IUser from '../../lib/models/user'
import { SignUpForm } from './sign-up-form'
import { ChurchPicker } from './church-picker'
import { IAccount } from '../../lib/models/account'
import { NewChurchForm } from './new-church'

interface IProps {
  ministry: IMinistry,
  thumbnail: IAsset,
  loginUrl: string,
  user: IUser,
  securedApiKey: string,
  indexName: string,
  provisionalAccountId: number | null
  reassignment?: boolean,
}

interface IState {
  email: string
  isNewChurch: boolean
}

export class PageFlowModal extends React.Component<IProps, IState> {
  private securityLevelId: number

  constructor(props: IProps) {
    super(props)

    this.state = {
      email: '',
      isNewChurch: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNewChurchChange = this.handleNewChurchChange.bind(this)

    // self-service invite always creates a provisional invitation as a lay leader
    // If this becomes a problem we can make this configurable
    this.securityLevelId = 3
  }

  public handleNewChurchChange(value: boolean) {
    this.setState({
      isNewChurch: value,
    })
  }

  public render() {
    const {
      ministry,
      loginUrl,
      user,
      securedApiKey,
      indexName,
      provisionalAccountId,
      reassignment
    } = this.props

    const thumbnail = ministry.fields.thumbnail as IAsset

    let form
    if (user && this.state.isNewChurch) {
      form = <NewChurchForm provisionalAccountId={provisionalAccountId} ministry={ministry} user={user} securedApiKey={securedApiKey} securityLevelId={this.securityLevelId} />
    } else if (user) {
      form = <ChurchPicker reassignment={reassignment} onNewChurchSelected={this.handleNewChurchChange} indexName={indexName} ministry={ministry} user={user} securedApiKey={securedApiKey} securityLevelId={this.securityLevelId} />
    } else {
      form = <SignUpForm ministry={ministry} loginUrl={loginUrl} />
    }

    return(
      <Modal
        isOpen={true}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        bodyOpenClassName='ReactModal__Body--open'
        className="ReactModal__WMRContent self-service">
          <div className='row'>
            <div className='col d-flex self-service__header'>
              <img alt='' src={thumbnail.fields.file.url + '?w=120'} />
              <div className='self-service__header-text'>
                <h4 className='self-service__abstract-title'>{ministry.fields.abstractTitle}</h4>
                <h3 className='self-service__title'>{ministry.fields.title}</h3>
              </div>
            </div>
          </div>
          <div className='row self-service__form'>
          <div className='col'>
            {form}
          </div>
          </div>
        </Modal>
    )
  }

  public async handleSubmit(e) {
    e.preventDefault()

    const win: Window = window
    const url = new URL(this.props.loginUrl)
    url.pathname = '/users/sign_up'

    url.searchParams.set('user[email]', this.state.email)
    url.searchParams.set('redirect_to', win.location.href)

    win.location.replace(url.toString())

    this.setState({
      email: '',
    })
  }
}
