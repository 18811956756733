import * as React from 'react'
import { ErrorBoundary } from '../components/error-boundary'
import {PageContextProvider} from './connectors/pageContext'

export default function wrapTopLevelComponent<P = any>(
  Component: React.ComponentType<P>,
): React.ComponentType<P> {
  return (props: any) => {
    return <ErrorBoundary>
      <PageContextProvider>
        <Component {...props}></Component>
      </PageContextProvider>
    </ErrorBoundary>
  }
}
