/* eslint-disable react-hooks/exhaustive-deps */
import bugsnagClient from 'bugsnag'
import { DependencyList, useCallback, useState } from 'react'

export type AsyncActionResult<T> =
  {
    wait: false,
    error?: undefined,
    data?: undefined,
  } | {
    wait: true,
    error?: undefined,
    data?: undefined
  } | {
    wait: false,
    error: Error,
    data?: undefined
  } | {
    wait: false,
    error?: undefined,
    data: T
  }

// TODO: remove this when we upgrade TS
type Awaited<T> = T extends PromiseLike<infer U> ? U : T

/**
 * Wraps an Async callback to track state and report errors.
 */
export function useAsyncAction<T extends (...args: any[]) => Promise<unknown>>(action: T, deps?: DependencyList) {

  const [result, setResult] = useState<AsyncActionResult<Awaited<ReturnType<T>>>>({
    wait: false,
  })
  const reset = useCallback(() => setResult({ wait: false }), [])

  deps = deps || []

  const wrapped: T = useCallback((...args: any[]) => {
    // restart the sequence
    setResult({
      wait: true,
    })

    // run the action
    const promise = action(...args)

    // update the state when the promise resolves, regardless
    // of how the caller uses the action's result.
    promise.then(
        (data) => {
          // success!
          setResult({
            wait: false,
            data: data as any
          })
        },
        (err) => {
          bugsnagClient.notify(err)
          console.error(err)
          setResult({
            wait: false,
            error: err
          })
        })

      // return the original promise to the caller
    return promise
  }, [...deps]) as T

  return [
    result,
    wrapped,
    reset
  ] as const
}
