import { Entry, IEntry } from "../base";

export interface IFormFieldFields {
  internalTitle?: never;
  title?: string;
  inputType: FormFieldInputType;
}

export type FormFieldInputType = 'text' | 'email' | 'textarea' | 'tel';

/**
 * Form Field
 * A Form Field represents a piece of information you want to collect from a user via a Form.
 */
export interface IFormField extends IEntry<IFormFieldFields> {
}

export function isFormField(entry: IEntry<any>): entry is IFormField {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'formField'
}

export class FormField extends Entry<IFormFieldFields> implements IFormField {
  get title(): string | undefined {
    return this.fields.title
  }

  get inputType(): FormFieldInputType {
    return this.fields.inputType
  }

  get input_type(): FormFieldInputType {
    return this.fields.inputType
  }

  constructor(entry: IFormField);
  constructor(id: string, fields: IFormFieldFields);
  constructor(entryOrId: IFormField | string, fields?: IFormFieldFields) {
    super(entryOrId, 'formField', fields)
  }
}
