import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IMinistry, Ministry } from "./ministry";

export interface ISectionLocationMapFields {
  internalTitle: string;
  domainObject: ILink<'Entry'> | IMinistry;
  includeVirtualEvents?: boolean;
  bookmarkTitle?: string;
}

/**
 * Section: Location Map
 * List ministry locations and plot them on a map
 */
export interface ISectionLocationMap extends IEntry<ISectionLocationMapFields> {
}

export function isSectionLocationMap(entry: IEntry<any>): entry is ISectionLocationMap {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-location-map'
}

export class SectionLocationMap extends Entry<ISectionLocationMapFields> implements ISectionLocationMap {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get domainObject(): Ministry | null {
    return (isEntry(this.fields.domainObject) ? wrap<'ministry'>(this.fields.domainObject) : null)
  }

  get domain_object(): Ministry | null {
    return (isEntry(this.fields.domainObject) ? wrap<'ministry'>(this.fields.domainObject) : null)
  }

  get includeVirtualEvents(): boolean | undefined {
    return this.fields.includeVirtualEvents
  }

  get include_virtual_events(): boolean | undefined {
    return this.fields.includeVirtualEvents
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionLocationMap);
  constructor(id: string, fields: ISectionLocationMapFields);
  constructor(entryOrId: ISectionLocationMap | string, fields?: ISectionLocationMapFields) {
    super(entryOrId, 'section-location-map', fields)
  }
}
