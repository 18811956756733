import React from "react";
import PlacePill from "./search-controls/place-pill";
import ConnectedSearchBox from "./search-controls/search-box";
const SearchControls = ({ google, onFilterToggle, displayFilters, searchAsYouType }) => React.createElement("div", { className: "location-map__pane__controls" },
    React.createElement(ConnectedSearchBox, { google: google, searchAsYouType: searchAsYouType }),
    React.createElement(PlacePill, null),
    React.createElement("a", { onClick: onFilterToggle, className: "btn btn-outline btn-outline-secondary filters" },
        "Filters ",
        React.createElement("i", { className: "icon material-icons" }, displayFilters ? 'expand_less' : 'expand_more')));
export default SearchControls;
