import { Ability as CaslAbility, RawRule } from '@casl/ability'
import { titleize, underscore } from 'inflection'
import * as React from 'react'
import WCC from 'wcc'

import { isEntry, wrap } from '../contentful'
import { Omit } from '../types'

export type Can = Ability['can']

/**
 * A wrapper around @casl/ability which wraps Contentful objects before passing
 * them to the underlying permission checker
 */
export class Ability {
  public readonly caslAbility: CaslAbility

  constructor(rules?: RawRule[]) {
    this.caslAbility = new CaslAbility(
      rules,
      {
        // uglify-js mangles our class names, rendering useless the default
        // casl behavior of checking `subject.constructor.name`.
        subjectName: getSubjectName,
      },
    )

    this.can = this.can.bind(this)
  }

  // tslint:disable:unified-signatures
  /**
   * Can I perform this action on the type as a whole?
   *
   * @param action 'create', 'read', 'update', 'destroy'
   * @param subject The type name
   * @example
   *   // can I read account ministry access objects in general?
   *   can('read', 'AccountMinistryAccess')
   */
  public can(action: string, subject: string): boolean
  /**
   * Can I perform this action on this instance of the object?
   *
   * Use this when the object is a constructed type
   * @param action 'create', 'read', 'update', 'destroy'
   * @param object The constructed object instance
   * @example
   *   // Can I read this AccountMinistryAccess object?
   *   const ama: IAMAccess = ...
   *   can('read', new AccountMinistryAccess(ama))
   */
  public can(action: string, subject: Record<string, any>): boolean
  /**
   * Can I perform this action on this instance of the object?
   *
   * Use this when the object is an inline JS object, not a constructed type
   * @param action 'create', 'read', 'update', 'destroy'
   * @param type The type of the object.
   * @param object The object instance
   * @example
   *   // can I read this JS hash?  Pretend it's an AccountMinistryAccess
   *   const ama: IAMAccess = ...
   *   can('read', 'AccountMinistryAccess', ama)
   */
  public can(action: string, type: string, object: Record<string, any>): boolean

  public can(action: string, subject: string | Record<string, any>, field?: string | Record<string, any>): boolean {
    return this.caslAbility.can(action,
      this.wrapSubject(subject, field),
      typeof(field) == 'string' && field)
  }

  private wrapSubject = (subject: any, field?: string | Record<string, any>) => {
    if (typeof(subject) == 'string' && typeof(field) == 'object') {
      // pretend that the second parameter is an instance of the subject type
      const name = subject
      subject = {
        getConstructorName: () => name,
        ...field,
      }
    } else if (subject && isEntry(subject) && subject.constructor === Object) {
      // wrap contentful entries
      return wrap(subject)
    }
    return subject
  }
}

const globalAbility = new Ability()
if (WCC && WCC.ability) {
  globalAbility.caslAbility.update(WCC.ability)
}

const AbilityContext = React.createContext({ ability: globalAbility })

interface IAbilityProvided {
  can: Can
}

/**
 * Wraps a React component type to inject the current Ability from the context.
 */
export function connectAbility<TProps extends Partial<IAbilityProvided>>(
  WrappedComponent: React.ComponentType<TProps>,
): React.ComponentType<Omit<TProps, IAbilityProvided>> {
  return (props) =>
    <AbilityContext.Consumer>
      { ({ ability }) =>
          <WrappedComponent {...props as TProps}
            can={ability.can} /> }
    </AbilityContext.Consumer>
}

/**
 * Matches a given subject to the keys specified in the WCC.ability ruleset
 * @param subject Any object that we are checking permissions against
 */
function getSubjectName(subject: any) {
  if (!subject || typeof subject === 'string') {
    return subject
  }

  // Rails models
  if (typeof (subject.getConstructorName) == 'function') {
    return subject.getConstructorName()
  }
  if (subject.constructor && typeof (subject.constructor.getConstructorName) == 'function') {
    return subject.constructor.getConstructorName()
  }

  // Contentful objects
  if (isEntry(subject) && subject.sys.contentType) {
    return idToName(subject.sys.contentType.sys.id)
  }

  throw new Error(`Cannot get subject name for \`${subject}\``)
}

function idToName(id: string): string {
  id = underscore(id)
  id = id.replace(/[^\w]/g, ' ')
  id = titleize(id)
  id = id.replace(/[\s+]/g, '')
  return id
}
