import wrapTopLevelComponent from './wrap-top-level-component'
import ReactRailsUJS from 'react_ujs'
import path from 'path'

const wrappedGetConstructor = ReactRailsUJS.getConstructor

ReactRailsUJS.getConstructor = function() {
  // eslint-disable-next-line prefer-rest-params
  const ctor = wrappedGetConstructor.apply(this, arguments)
  return wrapTopLevelComponent(ctor)
}

export function setupReact(
  componentRequireContext: Record<string, unknown>,
) {
  Object.keys(componentRequireContext).forEach((fullpath) => {
    const component = (componentRequireContext[fullpath] as any).default
    const componentName = path.basename(fullpath, path.extname(fullpath))

    window[componentName] = component
  })
}
