import { Entry, IEntry } from "../base";

export interface IMigrationHistoryFields {
  migrationName: string;
  started?: string;
  completed?: string;
  detail?: any;
}

/**
 * Migration History
 * System Type - Do Not Modify
 */
export interface IMigrationHistory extends IEntry<IMigrationHistoryFields> {
}

export function isMigrationHistory(entry: IEntry<any>): entry is IMigrationHistory {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'migrationHistory'
}

export class MigrationHistory extends Entry<IMigrationHistoryFields> implements IMigrationHistory {
  get migrationName(): string {
    return this.fields.migrationName
  }

  get migration_name(): string {
    return this.fields.migrationName
  }

  get started(): string | undefined {
    return this.fields.started
  }

  get completed(): string | undefined {
    return this.fields.completed
  }

  get detail(): any | undefined {
    return this.fields.detail
  }

  constructor(entry: IMigrationHistory);
  constructor(id: string, fields: IMigrationHistoryFields);
  constructor(entryOrId: IMigrationHistory | string, fields?: IMigrationHistoryFields) {
    super(entryOrId, 'migrationHistory', fields)
  }
}
