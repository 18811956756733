import WCC, {ISecurityLevel} from 'wcc'
import { Resource } from '../generated/resource'

declare module '../generated/resource' {
  // tslint:disable-next-line:interface-name
  export interface Resource {
    security_level_id: number
    'public?': boolean
  }
}

Object.defineProperty(Resource.prototype, 'security_level_id', {
  get(this: Resource) {
    const securityLevelName = typeof(this.securityLevel) == 'string' ?
      this.securityLevel :
      (this.securityLevel as ISecurityLevel).name

    const securityLevel = WCC.securityLevels.find((s) =>
      s.name == securityLevelName ||
      s.name_alias == securityLevelName)

    return securityLevel ? securityLevel.id : -1
  },
  enumerable: true,
})

Object.defineProperty(Resource.prototype, 'public?', {
  get(this: Resource) {
    return !this.securityLevel || this.securityLevel == 'Anonymous'
  },
  enumerable: true,
})

export {}
