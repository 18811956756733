
import WCC, { ISecurityLevel } from 'wcc'
import { Ministry } from '../generated/ministry'

declare module '../generated/ministry' {
  // tslint:disable-next-line:interface-name
  export interface Ministry {
    invitable_security_levels: ISecurityLevel[]
  }
}

// logic duplciated from app/models/ministry.rb
Object.defineProperty(Ministry.prototype, 'invitable_security_levels', {
  get() {
    if (this._invitable_security_levels) {
      return this._invitable_security_levels
    }

    const levels = [WCC.securityLevels.find((l) => l.key == 'ministry_leader')]
    if (this['allow_mentor?']) {
      levels.push(WCC.securityLevels.find((l) => l.key == 'mentor'))
    }
    return levels
  },
  set(v: ISecurityLevel[]) {
    this._invitable_security_levels = v
  },
  enumerable: true,
})

Object.defineProperty(Ministry.prototype, 'allow_mentor?', {
  get() {
    if (this._allow_mentor !== undefined) {
      return this._allow_mentor
    }

    return /re.*gen/i.test(this.title)
  },
  set(v: boolean) {
    this._allow_mentor = v
  },
  enumerable: true,
})

export {}
