import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { Card, ICard } from "./card";

export interface ISectionCardDeckFields {
  internalTitle: string;
  cards?: Array<ILink<'Entry'> | ICard>;
  title?: string;
  bookmarkTitle?: string;
  cardsPerRow?: number;
}

/**
 * Section: Card Deck
 */
export interface ISectionCardDeck extends IEntry<ISectionCardDeckFields> {
}

export function isSectionCardDeck(entry: IEntry<any>): entry is ISectionCardDeck {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-card-deck'
}

export class SectionCardDeck extends Entry<ISectionCardDeckFields> implements ISectionCardDeck {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get cards(): Array<Card | null> | undefined {
    return !this.fields.cards ? undefined :
      this.fields.cards.map((item) =>
        isEntry(item) ? wrap<'card'>(item) : null
      )
  }

  get title(): string | undefined {
    return this.fields.title
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get cardsPerRow(): number | undefined {
    return this.fields.cardsPerRow
  }

  get cards_per_row(): number | undefined {
    return this.fields.cardsPerRow
  }

  constructor(entry: ISectionCardDeck);
  constructor(id: string, fields: ISectionCardDeckFields);
  constructor(entryOrId: ISectionCardDeck | string, fields?: ISectionCardDeckFields) {
    super(entryOrId, 'section-card-deck', fields)
  }
}
