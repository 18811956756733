import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { ITestimonial, Testimonial } from "./testimonial";

export interface ISectionTestimonialsFields {
  internalTitle?: never;
  name: string;
  testimonials: Array<ILink<'Entry'> | ITestimonial>;
  style?: SectionTestimonialsStyle;
}

export type SectionTestimonialsStyle = 'default' | 'jumbo';

/**
 * Section: Testimonials
 * A Testimonials section can show one or many Testimonials written by leaders, partners, or members who have participated in the Ministry or Conference.
 */
export interface ISectionTestimonials extends IEntry<ISectionTestimonialsFields> {
}

export function isSectionTestimonials(entry: IEntry<any>): entry is ISectionTestimonials {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-testimonials'
}

export class SectionTestimonials extends Entry<ISectionTestimonialsFields> implements ISectionTestimonials {
  get name(): string {
    return this.fields.name
  }

  get testimonials(): Array<Testimonial | null> {
    return this.fields.testimonials.map((item) =>
      isEntry(item) ? wrap<'testimonial'>(item) : null
    )
  }

  get style(): SectionTestimonialsStyle | undefined {
    return this.fields.style
  }

  constructor(entry: ISectionTestimonials);
  constructor(id: string, fields: ISectionTestimonialsFields);
  constructor(entryOrId: ISectionTestimonials | string, fields?: ISectionTestimonialsFields) {
    super(entryOrId, 'section-testimonials', fields)
  }
}
