import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IResourceSet, ResourceSet } from "./resource_set";

export interface IResourceTreeFields {
  internalTitle: string;
  title: string;
  ionicon?: string;
  blurb?: string;
  branches?: Array<ILink<'Entry'> | ResourceTreeBranch>;
}

export type ResourceTreeBranch = IResourceSet | IResourceTree;
export type ResourceTreeBranchClass = ResourceSet | ResourceTree;

/**
 * Resource Tree
 */
export interface IResourceTree extends IEntry<IResourceTreeFields> {
}

export function isResourceTree(entry: IEntry<any>): entry is IResourceTree {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'resourceTree'
}

export class ResourceTree extends Entry<IResourceTreeFields> implements IResourceTree {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get title(): string {
    return this.fields.title
  }

  get ionicon(): string | undefined {
    return this.fields.ionicon
  }

  get blurb(): string | undefined {
    return this.fields.blurb
  }

  get branches(): Array<ResourceTreeBranchClass | null> | undefined {
    return !this.fields.branches ? undefined :
      this.fields.branches.map((item) =>
        isEntry(item) ? wrap<'resourceSet' | 'resourceTree'>(item) : null
      )
  }

  constructor(entry: IResourceTree);
  constructor(id: string, fields: IResourceTreeFields);
  constructor(entryOrId: IResourceTree | string, fields?: IResourceTreeFields) {
    super(entryOrId, 'resourceTree', fields)
  }
}
