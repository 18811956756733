import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { CartButton, ICartButton } from "./cart_button";
import { ILoginButton, LoginButton } from "./login_button";
import { IMenuButton, MenuButton } from "./menu_button";

export interface IMenuFields {
  internalTitle?: never;
  name?: string;
  label?: ILink<'Entry'> | IMenuButton;
  items?: Array<ILink<'Entry'> | MenuItem>;
}

export type MenuItem = ICartButton | ILoginButton | IMenu | IMenuButton;
export type MenuItemClass = CartButton | LoginButton | Menu | MenuButton;

/**
 * Menu
 * A Menu contains a number of Menu Buttons or other Menus, which will be rendered as drop-downs.
 */
export interface IMenu extends IEntry<IMenuFields> {
}

export function isMenu(entry: IEntry<any>): entry is IMenu {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'menu'
}

export class Menu extends Entry<IMenuFields> implements IMenu {
  get name(): string | undefined {
    return this.fields.name
  }

  get label(): MenuButton | null | undefined {
    return !this.fields.label ? undefined :
      (isEntry(this.fields.label) ? wrap<'menuButton'>(this.fields.label) : null)
  }

  get items(): Array<MenuItemClass | null> | undefined {
    return !this.fields.items ? undefined :
      this.fields.items.map((item) =>
        isEntry(item) ? wrap<'cartButton' | 'loginButton' | 'menu' | 'menuButton'>(item) : null
      )
  }

  constructor(entry: IMenu);
  constructor(id: string, fields: IMenuFields);
  constructor(entryOrId: IMenu | string, fields?: IMenuFields) {
    super(entryOrId, 'menu', fields)
  }
}
