import { Entry, IEntry } from "../base";

export interface IFaqFields {
  internalTitle?: never;
  question: string;
  answer?: string;
  externalResourceLink?: string;
}

/**
 * Faq
 * A faq is a question + answer pair used in the Faq section on a page
 */
export interface IFaq extends IEntry<IFaqFields> {
}

export function isFaq(entry: IEntry<any>): entry is IFaq {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'faq'
}

export class Faq extends Entry<IFaqFields> implements IFaq {
  get question(): string {
    return this.fields.question
  }

  get answer(): string | undefined {
    return this.fields.answer
  }

  get externalResourceLink(): string | undefined {
    return this.fields.externalResourceLink
  }

  get external_resource_link(): string | undefined {
    return this.fields.externalResourceLink
  }

  constructor(entry: IFaq);
  constructor(id: string, fields: IFaqFields);
  constructor(entryOrId: IFaq | string, fields?: IFaqFields) {
    super(entryOrId, 'faq', fields)
  }
}
