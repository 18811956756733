import * as React from 'react';
import { connectCurrentSelectedLocation } from '../connectors/connectCurrentSelectedLocation';
import { shareSearch } from '../utils/shareSearch';
class VirtualLayout extends React.Component {
    render() {
        const { SearchControls, FilterPane, SelectedLocationPane, ResultListPane, selectedLocation, onClearSelectedLocation, children } = this.props;
        const { displayFilters } = this.state || {};
        return (React.createElement("div", { className: `location-map ${selectedLocation ? 'location-selected' : 'no-location-selected'}` },
            React.createElement("div", { className: `location-map__content` },
                React.createElement("div", { className: `location-map__pane location-map__pane-filters ${displayFilters ? '' : 'hidden'}` },
                    React.createElement(FilterPane, { displayFilters: displayFilters, onFilterToggle: () => this.setState({ displayFilters: !displayFilters }) })),
                React.createElement("div", { className: `location-map__pane location-map__pane-list` },
                    React.createElement(SearchControls, { displayFilters: displayFilters, onFilterToggle: () => this.setState({ displayFilters: !displayFilters }) }),
                    React.createElement(ResultListPane, null)),
                children),
            React.createElement("div", { className: `location-map__pane location-map__pane__virtual` },
                selectedLocation &&
                    React.createElement("div", { className: "location-map__pane__controls" },
                        React.createElement("a", { className: "text-button", onClick: onClearSelectedLocation },
                            React.createElement("i", { className: "material-icons" }, "keyboard_backspace"),
                            " Back to All Locations"),
                        navigator.clipboard && (React.createElement("a", { href: "#", className: 'share-search', onClick: shareSearch },
                            React.createElement("i", { className: 'material-icons' }, "share")))),
                selectedLocation && React.createElement(SelectedLocationPane, null))));
    }
}
export const ConnectedVirtualLayout = connectCurrentSelectedLocation(VirtualLayout);
