import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IPage, Page } from "./page";
import { IResource, Resource } from "./resource";

export interface IResourceSetFields {
  internalTitle: string;
  title: string;
  blurb?: string;
  ionicon?: string;
  parentPage: ILink<'Entry'> | IPage;
  resources?: Array<ILink<'Entry'> | IResource>;
}

/**
 * Resource Set
 */
export interface IResourceSet extends IEntry<IResourceSetFields> {
}

export function isResourceSet(entry: IEntry<any>): entry is IResourceSet {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'resourceSet'
}

export class ResourceSet extends Entry<IResourceSetFields> implements IResourceSet {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get title(): string {
    return this.fields.title
  }

  get blurb(): string | undefined {
    return this.fields.blurb
  }

  get ionicon(): string | undefined {
    return this.fields.ionicon
  }

  get parentPage(): Page | null {
    return (isEntry(this.fields.parentPage) ? wrap<'page'>(this.fields.parentPage) : null)
  }

  get parent_page(): Page | null {
    return (isEntry(this.fields.parentPage) ? wrap<'page'>(this.fields.parentPage) : null)
  }

  get resources(): Array<Resource | null> | undefined {
    return !this.fields.resources ? undefined :
      this.fields.resources.map((item) =>
        isEntry(item) ? wrap<'resource'>(item) : null
      )
  }

  constructor(entry: IResourceSet);
  constructor(id: string, fields: IResourceSetFields);
  constructor(entryOrId: IResourceSet | string, fields?: IResourceSetFields) {
    super(entryOrId, 'resourceSet', fields)
  }
}
