import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IMenu, Menu } from "./menu";
import { IMenuButton, MenuButton } from "./menu_button";
import { IPage, Page } from "./page";
import { IResourceSet, ResourceSet } from "./resource_set";
import { ISectionBlockText, SectionBlockText } from "./section-block-text";
import { ISectionCard, SectionCard } from "./section-card";
import { ISectionCodeWidget, SectionCodeWidget } from "./section-code-widget";
import { ISectionContactUs, SectionContactUs } from "./section-contact-us";
import { ISectionDomainObjectHeader, SectionDomainObjectHeader } from "./section-domain-object-header";
import { ISectionEmailCapture, SectionEmailCapture } from "./section-email-capture";
import { ISectionFaq, SectionFaq } from "./section-faq";
import { ISectionFeaturedItems, SectionFeaturedItems } from "./section-featured-items";
import { ISectionHero, SectionHero } from "./section-hero";
import { ISectionImageGallery, SectionImageGallery } from "./section-image-gallery";
import { ISectionIntro, SectionIntro } from "./section-intro";
import { ISectionLocationMap, SectionLocationMap } from "./section-location-map";
import { ISectionMarqueeText, SectionMarqueeText } from "./section-marquee-text";
import { ISectionMinistryDetails, SectionMinistryDetails } from "./section-ministry-details";
import { ISectionPartnerChurches, SectionPartnerChurches } from "./section-partner-churches";
import { ISectionProductList, SectionProductList } from "./section-product-list";
import { ISectionTestimonials, SectionTestimonials } from "./section-testimonials";
import { ISectionVideo, SectionVideo } from "./section-video";
import { ISectionVideoHighlight, SectionVideoHighlight } from "./section-video-highlight";

export interface ISectionResourceLandingFields {
  name: string;
  title: string;
  pills?: Array<ILink<'Entry'> | IMenuButton>;
  searchPage?: ILink<'Entry'> | IPage;
  lessonSets?: Array<ILink<'Entry'> | IMenu>;
  resourceSet?: ILink<'Entry'> | IResourceSet;
  sections?: Array<ILink<'Entry'> | SectionResourceLandingSection>;
}

export type SectionResourceLandingSection = ISectionBlockText | ISectionCard | ISectionCodeWidget | ISectionContactUs | ISectionEmailCapture | ISectionFaq | ISectionFeaturedItems | ISectionHero | ISectionImageGallery | ISectionIntro | ISectionLocationMap | ISectionMarqueeText | ISectionMinistryDetails | ISectionDomainObjectHeader | ISectionPartnerChurches | ISectionProductList | ISectionTestimonials | ISectionVideo | ISectionVideoHighlight;
export type SectionResourceLandingSectionClass = SectionBlockText | SectionCard | SectionCodeWidget | SectionContactUs | SectionEmailCapture | SectionFaq | SectionFeaturedItems | SectionHero | SectionImageGallery | SectionIntro | SectionLocationMap | SectionMarqueeText | SectionMinistryDetails | SectionDomainObjectHeader | SectionPartnerChurches | SectionProductList | SectionTestimonials | SectionVideo | SectionVideoHighlight;

/**
 * Section: Resource Landing
 */
export interface ISectionResourceLanding extends IEntry<ISectionResourceLandingFields> {
}

export function isSectionResourceLanding(entry: IEntry<any>): entry is ISectionResourceLanding {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-resource-landing'
}

export class SectionResourceLanding extends Entry<ISectionResourceLandingFields> implements ISectionResourceLanding {
  get name(): string {
    return this.fields.name
  }

  get title(): string {
    return this.fields.title
  }

  get pills(): Array<MenuButton | null> | undefined {
    return !this.fields.pills ? undefined :
      this.fields.pills.map((item) =>
        isEntry(item) ? wrap<'menuButton'>(item) : null
      )
  }

  get searchPage(): Page | null | undefined {
    return !this.fields.searchPage ? undefined :
      (isEntry(this.fields.searchPage) ? wrap<'page'>(this.fields.searchPage) : null)
  }

  get search_page(): Page | null | undefined {
    return !this.fields.searchPage ? undefined :
      (isEntry(this.fields.searchPage) ? wrap<'page'>(this.fields.searchPage) : null)
  }

  get lessonSets(): Array<Menu | null> | undefined {
    return !this.fields.lessonSets ? undefined :
      this.fields.lessonSets.map((item) =>
        isEntry(item) ? wrap<'menu'>(item) : null
      )
  }

  get lesson_sets(): Array<Menu | null> | undefined {
    return !this.fields.lessonSets ? undefined :
      this.fields.lessonSets.map((item) =>
        isEntry(item) ? wrap<'menu'>(item) : null
      )
  }

  get resourceSet(): ResourceSet | null | undefined {
    return !this.fields.resourceSet ? undefined :
      (isEntry(this.fields.resourceSet) ? wrap<'resourceSet'>(this.fields.resourceSet) : null)
  }

  get resource_set(): ResourceSet | null | undefined {
    return !this.fields.resourceSet ? undefined :
      (isEntry(this.fields.resourceSet) ? wrap<'resourceSet'>(this.fields.resourceSet) : null)
  }

  get sections(): Array<SectionResourceLandingSectionClass | null> | undefined {
    return !this.fields.sections ? undefined :
      this.fields.sections.map((item) =>
        isEntry(item) ? wrap<'section-block-text' | 'section-card' | 'section-code-widget' | 'section-contact-us' | 'section-email-capture' | 'section-faq' | 'section-featured-items' | 'section-hero' | 'section-image-gallery' | 'section-intro' | 'section-location-map' | 'section-marquee-text' | 'section-ministry-details' | 'section-domain-object-header' | 'section-partner-churches' | 'section-product-list' | 'section-testimonials' | 'section-video' | 'section-video-highlight'>(item) : null
      )
  }

  constructor(entry: ISectionResourceLanding);
  constructor(id: string, fields: ISectionResourceLandingFields);
  constructor(entryOrId: ISectionResourceLanding | string, fields?: ISectionResourceLandingFields) {
    super(entryOrId, 'section-resource-landing', fields)
  }
}
