import * as React from 'react';
import { ClearRefinements, ToggleRefinement } from 'react-instantsearch-dom';
import WDayRefinement from './filter-pane/wday-refinement';
const FilterPane = ({ translate, onFilterToggle }) => React.createElement(React.Fragment, null,
    React.createElement("div", { className: "location-map__pane__controls location-map__pane__controls-top" },
        React.createElement("h4", { className: "location-map__pane__controls__title" }, "Filter Results"),
        React.createElement("a", { onClick: onFilterToggle, className: "location-map__pane__controls__control btn btn-outline btn-outline-secondary filters" },
            "Hide Filters ",
            React.createElement("i", { className: "icon material-icons" }, "arrow_drop_up"))),
    React.createElement("div", { className: "location-map__pane__widget location-map__pane__meetingDay" },
        React.createElement("h4", { className: "label" }, translate('locationMap.filters.meetingDay') || 'Meeting Day'),
        React.createElement(WDayRefinement, { attribute: "meetingDay", defaultRefinement: [], translate: translate })),
    React.createElement("div", { className: "location-map__pane__widget" },
        React.createElement("div", { className: "location-map__pane__toggle-refinement" },
            React.createElement("span", { className: "overline" }, translate('locationMap.filters.trainingLocation.overline')),
            React.createElement(ToggleRefinement, { attribute: "trainingLocation", label: translate('locationMap.filters.trainingLocation.label') || 'Training Location', value: true }))),
    React.createElement("div", { className: "location-map__pane__widget" },
        React.createElement("div", { className: "location-map__pane__toggle-refinement" },
            React.createElement("span", { className: "overline" }, translate('locationMap.filters.has_spanish.overline')),
            React.createElement(ToggleRefinement, { attribute: "has_spanish", label: translate('locationMap.filters.has_spanish.label') || 'En Español', value: true }))),
    React.createElement("div", { className: "location-map__pane__controls location-map__pane__controls-bottom" },
        React.createElement(ClearRefinements, { translations: { reset: translate('locationMap.filters.reset') || 'Reset Filters' } }),
        React.createElement("a", { onClick: onFilterToggle, className: "btn btn-outline filters" },
            translate('locationMap.filters.hideFilters') || 'Apply Filters',
            " ",
            React.createElement("i", { className: "icon material-icons" }, "arrow_drop_up"))));
export default FilterPane;
