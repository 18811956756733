import { present } from "../utils";
export function shareSearch(e) {
    e.preventDefault();
    const shareUrl = new URL(window.location.href);
    // If there exists a parent with an ID, append that as the hash
    const parent = e.currentTarget.closest('section[id]');
    if (parent && !present(shareUrl.hash)) {
        shareUrl.hash = parent.id;
    }
    // copy the URL to the clipboard
    navigator.clipboard.writeText(shareUrl.toString())
        .then(() => {
        alert('URL copied to clipboard');
    })
        .catch(() => {
        alert('Failed to copy URL to clipboard');
    });
}
