import { wrap } from ".";
import { Asset, Entry, IAsset, IEntry, ILink, isAsset, isEntry } from "../base";
import { IPage, Page } from "./page";
import { IResourceTree, ResourceTree } from "./resource_tree";

export interface IMinistryFields {
  internalTitle?: never;
  title: string;
  abstractTitle?: string;
  thumbnail?: ILink<'Asset'> | IAsset;
  logo?: ILink<'Asset'> | IAsset;
  backgroundColor: string;
  foregroundColor: string;
  homepage: ILink<'Entry'> | IPage;
  interestFormLandingPage?: ILink<'Entry'> | IPage;
  flags?: MinistryFlag[];
  details?: any;
  website?: string;
  tags?: string[];
  sequence?: number;
  publicResources?: ILink<'Entry'> | IResourceTree;
  mentorResources?: ILink<'Entry'> | IResourceTree;
  leaderResources?: ILink<'Entry'> | IResourceTree;
  adminResources?: ILink<'Entry'> | IResourceTree;
  superAdminResources?: ILink<'Entry'> | IResourceTree;
  registrationTemplate?: MinistryRegistrationTemplate;
  registrationConfig?: any;
}

export type MinistryFlag = 'hide from internal search';
export type MinistryRegistrationTemplate = 'online-equipping';

/**
 * Ministry
 */
export interface IMinistry extends IEntry<IMinistryFields> {
}

export function isMinistry(entry: IEntry<any>): entry is IMinistry {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'ministry'
}

export class Ministry extends Entry<IMinistryFields> implements IMinistry {
  get title(): string {
    return this.fields.title
  }

  get abstractTitle(): string | undefined {
    return this.fields.abstractTitle
  }

  get abstract_title(): string | undefined {
    return this.fields.abstractTitle
  }

  get thumbnail(): Asset | null | undefined {
    return !this.fields.thumbnail ? undefined :
      (isAsset(this.fields.thumbnail) ? new Asset(this.fields.thumbnail) : null)
  }

  get logo(): Asset | null | undefined {
    return !this.fields.logo ? undefined :
      (isAsset(this.fields.logo) ? new Asset(this.fields.logo) : null)
  }

  get backgroundColor(): string {
    return this.fields.backgroundColor
  }

  get background_color(): string {
    return this.fields.backgroundColor
  }

  get foregroundColor(): string {
    return this.fields.foregroundColor
  }

  get foreground_color(): string {
    return this.fields.foregroundColor
  }

  get homepage(): Page | null {
    return (isEntry(this.fields.homepage) ? wrap<'page'>(this.fields.homepage) : null)
  }

  get interestFormLandingPage(): Page | null | undefined {
    return !this.fields.interestFormLandingPage ? undefined :
      (isEntry(this.fields.interestFormLandingPage) ? wrap<'page'>(this.fields.interestFormLandingPage) : null)
  }

  get interest_form_landing_page(): Page | null | undefined {
    return !this.fields.interestFormLandingPage ? undefined :
      (isEntry(this.fields.interestFormLandingPage) ? wrap<'page'>(this.fields.interestFormLandingPage) : null)
  }

  get flags(): MinistryFlag[] | undefined {
    return this.fields.flags
  }

  get details(): any | undefined {
    return this.fields.details
  }

  get website(): string | undefined {
    return this.fields.website
  }

  get tags(): string[] | undefined {
    return this.fields.tags
  }

  get sequence(): number | undefined {
    return this.fields.sequence
  }

  get publicResources(): ResourceTree | null | undefined {
    return !this.fields.publicResources ? undefined :
      (isEntry(this.fields.publicResources) ? wrap<'resourceTree'>(this.fields.publicResources) : null)
  }

  get public_resources(): ResourceTree | null | undefined {
    return !this.fields.publicResources ? undefined :
      (isEntry(this.fields.publicResources) ? wrap<'resourceTree'>(this.fields.publicResources) : null)
  }

  get mentorResources(): ResourceTree | null | undefined {
    return !this.fields.mentorResources ? undefined :
      (isEntry(this.fields.mentorResources) ? wrap<'resourceTree'>(this.fields.mentorResources) : null)
  }

  get mentor_resources(): ResourceTree | null | undefined {
    return !this.fields.mentorResources ? undefined :
      (isEntry(this.fields.mentorResources) ? wrap<'resourceTree'>(this.fields.mentorResources) : null)
  }

  get leaderResources(): ResourceTree | null | undefined {
    return !this.fields.leaderResources ? undefined :
      (isEntry(this.fields.leaderResources) ? wrap<'resourceTree'>(this.fields.leaderResources) : null)
  }

  get leader_resources(): ResourceTree | null | undefined {
    return !this.fields.leaderResources ? undefined :
      (isEntry(this.fields.leaderResources) ? wrap<'resourceTree'>(this.fields.leaderResources) : null)
  }

  get adminResources(): ResourceTree | null | undefined {
    return !this.fields.adminResources ? undefined :
      (isEntry(this.fields.adminResources) ? wrap<'resourceTree'>(this.fields.adminResources) : null)
  }

  get admin_resources(): ResourceTree | null | undefined {
    return !this.fields.adminResources ? undefined :
      (isEntry(this.fields.adminResources) ? wrap<'resourceTree'>(this.fields.adminResources) : null)
  }

  get superAdminResources(): ResourceTree | null | undefined {
    return !this.fields.superAdminResources ? undefined :
      (isEntry(this.fields.superAdminResources) ? wrap<'resourceTree'>(this.fields.superAdminResources) : null)
  }

  get super_admin_resources(): ResourceTree | null | undefined {
    return !this.fields.superAdminResources ? undefined :
      (isEntry(this.fields.superAdminResources) ? wrap<'resourceTree'>(this.fields.superAdminResources) : null)
  }

  get registrationTemplate(): MinistryRegistrationTemplate | undefined {
    return this.fields.registrationTemplate
  }

  get registration_template(): MinistryRegistrationTemplate | undefined {
    return this.fields.registrationTemplate
  }

  get registrationConfig(): any | undefined {
    return this.fields.registrationConfig
  }

  get registration_config(): any | undefined {
    return this.fields.registrationConfig
  }

  constructor(entry: IMinistry);
  constructor(id: string, fields: IMinistryFields);
  constructor(entryOrId: IMinistry | string, fields?: IMinistryFields) {
    super(entryOrId, 'ministry', fields)
  }
}
