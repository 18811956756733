import { Asset, Entry, IAsset, IEntry, ILink, isAsset } from "../base";

export interface ITestimonialFields {
  internalTitle?: never;
  name: string;
  miniBio?: string;
  photo?: ILink<'Asset'> | IAsset;
  quote: string;
  linkText?: string;
}

/**
 * Testimonial
 * A Testimonial must be attached to a Testimonial Section to be displayed.
 */
export interface ITestimonial extends IEntry<ITestimonialFields> {
}

export function isTestimonial(entry: IEntry<any>): entry is ITestimonial {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'testimonial'
}

export class Testimonial extends Entry<ITestimonialFields> implements ITestimonial {
  get name(): string {
    return this.fields.name
  }

  get miniBio(): string | undefined {
    return this.fields.miniBio
  }

  get mini_bio(): string | undefined {
    return this.fields.miniBio
  }

  get photo(): Asset | null | undefined {
    return !this.fields.photo ? undefined :
      (isAsset(this.fields.photo) ? new Asset(this.fields.photo) : null)
  }

  get quote(): string {
    return this.fields.quote
  }

  get linkText(): string | undefined {
    return this.fields.linkText
  }

  get link_text(): string | undefined {
    return this.fields.linkText
  }

  constructor(entry: ITestimonial);
  constructor(id: string, fields: ITestimonialFields);
  constructor(entryOrId: ITestimonial | string, fields?: ITestimonialFields) {
    super(entryOrId, 'testimonial', fields)
  }
}
