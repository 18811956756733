import { Entry, IEntry } from "../base";

export interface ISectionVideoFields {
  internalTitle?: never;
  title?: string;
  embedCode: string;
  style?: SectionVideoStyle;
  bookmarkTitle?: string;
}

export type SectionVideoStyle = 'off white' | 'default' | 'gray';

/**
 * Section: Video
 * Full width video with a title
 */
export interface ISectionVideo extends IEntry<ISectionVideoFields> {
}

export function isSectionVideo(entry: IEntry<any>): entry is ISectionVideo {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-video'
}

export class SectionVideo extends Entry<ISectionVideoFields> implements ISectionVideo {
  get title(): string | undefined {
    return this.fields.title
  }

  get embedCode(): string {
    return this.fields.embedCode
  }

  get embed_code(): string {
    return this.fields.embedCode
  }

  get style(): SectionVideoStyle | undefined {
    return this.fields.style
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  constructor(entry: ISectionVideo);
  constructor(id: string, fields: ISectionVideoFields);
  constructor(entryOrId: ISectionVideo | string, fields?: ISectionVideoFields) {
    super(entryOrId, 'section-video', fields)
  }
}
