import { Asset, Entry, IAsset, IEntry, ILink, isAsset } from "../base";

export interface ILoginButtonFields {
  internalTitle?: never;
  loginText?: LoginButtonLoginText;
  loginIcon?: ILink<'Asset'> | IAsset;
  loginIonIcon?: string;
  logoutText?: LoginButtonLogoutText;
  logoutIcon?: ILink<'Asset'> | IAsset;
  logoutIonIcon?: string;
  style?: LoginButtonStyle;
  size?: LoginButtonSize;
}

export type LoginButtonLoginText = 'Log In' | 'Sign In';
export type LoginButtonLogoutText = 'Log Out' | 'Sign Out';
export type LoginButtonStyle = 'oval-border';
export type LoginButtonSize = 'large' | 'medium' | 'small';

/**
 * Login Button
 */
export interface ILoginButton extends IEntry<ILoginButtonFields> {
}

export function isLoginButton(entry: IEntry<any>): entry is ILoginButton {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'loginButton'
}

export class LoginButton extends Entry<ILoginButtonFields> implements ILoginButton {
  get loginText(): LoginButtonLoginText | undefined {
    return this.fields.loginText
  }

  get login_text(): LoginButtonLoginText | undefined {
    return this.fields.loginText
  }

  get loginIcon(): Asset | null | undefined {
    return !this.fields.loginIcon ? undefined :
      (isAsset(this.fields.loginIcon) ? new Asset(this.fields.loginIcon) : null)
  }

  get login_icon(): Asset | null | undefined {
    return !this.fields.loginIcon ? undefined :
      (isAsset(this.fields.loginIcon) ? new Asset(this.fields.loginIcon) : null)
  }

  get loginIonIcon(): string | undefined {
    return this.fields.loginIonIcon
  }

  get login_ion_icon(): string | undefined {
    return this.fields.loginIonIcon
  }

  get logoutText(): LoginButtonLogoutText | undefined {
    return this.fields.logoutText
  }

  get logout_text(): LoginButtonLogoutText | undefined {
    return this.fields.logoutText
  }

  get logoutIcon(): Asset | null | undefined {
    return !this.fields.logoutIcon ? undefined :
      (isAsset(this.fields.logoutIcon) ? new Asset(this.fields.logoutIcon) : null)
  }

  get logout_icon(): Asset | null | undefined {
    return !this.fields.logoutIcon ? undefined :
      (isAsset(this.fields.logoutIcon) ? new Asset(this.fields.logoutIcon) : null)
  }

  get logoutIonIcon(): string | undefined {
    return this.fields.logoutIonIcon
  }

  get logout_ion_icon(): string | undefined {
    return this.fields.logoutIonIcon
  }

  get style(): LoginButtonStyle | undefined {
    return this.fields.style
  }

  get size(): LoginButtonSize | undefined {
    return this.fields.size
  }

  constructor(entry: ILoginButton);
  constructor(id: string, fields: ILoginButtonFields);
  constructor(entryOrId: ILoginButton | string, fields?: ILoginButtonFields) {
    super(entryOrId, 'loginButton', fields)
  }
}
