import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IMenuButton, MenuButton } from "./menu_button";
import { ISectionEmailCapture, SectionEmailCapture } from "./section-email-capture";

export interface ICalltoActionFields {
  internalTitle?: never;
  title: string;
  text?: string;
  style?: CalltoActionStyle;
  actionButtons?: Array<ILink<'Entry'> | CalltoActionActionButton>;
}

export type CalltoActionStyle = 'default' | 'notification';
export type CalltoActionActionButton = IMenuButton | ISectionEmailCapture;
export type CalltoActionActionButtonClass = MenuButton | SectionEmailCapture;

/**
 * Call To Action
 * A Call To Action draws the user's attention to a particular button that you want them to click on.
 */
export interface ICalltoAction extends IEntry<ICalltoActionFields> {
}

export function isCalltoAction(entry: IEntry<any>): entry is ICalltoAction {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'callToAction'
}

export class CalltoAction extends Entry<ICalltoActionFields> implements ICalltoAction {
  get title(): string {
    return this.fields.title
  }

  get text(): string | undefined {
    return this.fields.text
  }

  get style(): CalltoActionStyle | undefined {
    return this.fields.style
  }

  get actionButtons(): Array<CalltoActionActionButtonClass | null> | undefined {
    return !this.fields.actionButtons ? undefined :
      this.fields.actionButtons.map((item) =>
        isEntry(item) ? wrap<'menuButton' | 'section-email-capture'>(item) : null
      )
  }

  get action_buttons(): Array<CalltoActionActionButtonClass | null> | undefined {
    return !this.fields.actionButtons ? undefined :
      this.fields.actionButtons.map((item) =>
        isEntry(item) ? wrap<'menuButton' | 'section-email-capture'>(item) : null
      )
  }

  constructor(entry: ICalltoAction);
  constructor(id: string, fields: ICalltoActionFields);
  constructor(entryOrId: ICalltoAction | string, fields?: ICalltoActionFields) {
    super(entryOrId, 'callToAction', fields)
  }
}
