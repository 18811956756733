import * as React from 'react';
import format from 'date-fns/format';
import endOfDay from 'date-fns/endOfDay';
import { present, renderAddress, renderMeetingTime, renderMeetingTZ, renderWebsite } from './utils';
import { parseTzlessDate } from './utils/parseTzlessDate';
import { connectCurrentSelectedLocation } from './connectors/connectCurrentSelectedLocation';
export class VirtualLocationDetailPane extends React.Component {
    ref;
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }
    componentDidMount() {
        // enable tooltips if tooltips enabled
        if (this.ref.current && typeof $ != 'undefined' && typeof $.fn.tooltip == 'function') {
            $(this.ref.current).find('[data-toggle="tooltip"]').tooltip();
        }
    }
    render() {
        const location = this.props.selectedLocation;
        const { translate } = this.props;
        const genderKey = location.genders.length > 1 ? 'all' : location.genders[0];
        return (React.createElement("div", { className: `location-details ${location.trainingLocation && 'training-location'}`, ref: this.ref },
            location.start_date &&
                React.createElement(StartDateBug, { translate: translate, startDate: location.start_date }),
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__group" },
                        React.createElement("h3", { className: "location-details__title" },
                            renderMeetingTime(location, translate),
                            " ",
                            renderMeetingTZ(location.meetingTimeZone, location.next_occurrence && location.next_occurrence.start_time_ts))))),
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__meta location-details__meta__host" },
                        React.createElement("i", { className: "material-icons", ...location.trainingLocation && {
                                'data-toggle': "tooltip",
                                'data-placement': "top",
                                'title': "Training Location"
                            } }, "stars"),
                        React.createElement("h6", { className: "label" }, "Host"),
                        React.createElement("p", null, location.title)))),
            location.address && React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__meta location-details__meta__location" },
                        React.createElement("i", { className: "material-icons" }, "location_on"),
                        React.createElement("h6", { className: "label" }, "Address"),
                        React.createElement("a", { href: `https://www.google.com/maps/place/${encodeURIComponent(renderAddress(location.address))}`, target: "_blank", rel: "noreferrer" }, renderAddress(location.address))))),
            React.createElement("div", { className: "row no-gutters" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "location-details__meta location-details__meta__gender" },
                        React.createElement("i", { className: "material-icons" }, "wc"),
                        React.createElement("h6", { className: "label" }, "Gender"),
                        React.createElement("p", null, translate(['locationMap', 'meetingGender', genderKey]))))),
            location.cta_buttons && location.cta_buttons.filter(present).length > 0 &&
                React.createElement("div", { className: "row no-gutters" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "location-details__meta location-details__meta__cta-buttons" }, location.cta_buttons.filter(present).map((btn) => (React.createElement("a", { key: btn.href, href: btn.href, className: `btn btn-${btn.style}` }, btn.text)))))),
            React.createElement("hr", null),
            location.contact && location.contact.phone &&
                React.createElement("div", { className: "row no-gutters" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "location-details__meta location-details__meta__phone" },
                            React.createElement("i", { className: "material-icons" }, "local_phone"),
                            React.createElement("h6", { className: "label" }, "Phone"),
                            React.createElement("a", { href: `tel:${location.contact.phone}` }, location.contact.phone)))),
            location.website &&
                React.createElement("div", { className: "row no-gutters" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "location-details__meta location-details__meta__website" },
                            React.createElement("i", { className: "material-icons" }, "computer"),
                            React.createElement("h6", { className: "label" }, "Website"),
                            React.createElement("a", { href: location.website, target: "_blank", rel: "noreferrer" }, renderWebsite(location.website))))),
            location.contact && location.contact.email &&
                React.createElement("div", { className: "row no-gutters" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "location-details__meta location-details__meta__email" },
                            React.createElement("i", { className: "material-icons" }, "send"),
                            React.createElement("h6", { className: "label" }, "Email"),
                            React.createElement("a", { href: `mailto:${location.contact.email}` }, location.contact.email)))),
            (location.about_ministry || location.about_account) && React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                location.about_ministry &&
                    React.createElement("div", { className: "row no-gutters location-details__about-row" },
                        React.createElement("h6", { className: "location-details__about-row-label" }, "About the Program"),
                        React.createElement("div", { className: "col-12" },
                            React.createElement("p", { style: { whiteSpace: 'pre-wrap' } }, location.about_ministry))),
                location.about_account &&
                    React.createElement("div", { className: "row no-gutters location-details__about-row" },
                        React.createElement("h6", { className: "location-details__about-row-label" },
                            "About ",
                            location.title),
                        React.createElement("div", { className: "col-12" },
                            React.createElement("p", { style: { whiteSpace: 'pre-wrap' } }, location.about_account))))));
    }
}
const StartDateBug = ({ startDate, translate }) => {
    const parsedStartDate = Date.parse(parseTzlessDate(startDate));
    const eod = endOfDay(parsedStartDate);
    // if the start date is yesterday or before, don't show the bug
    if (eod < new Date(Date.now())) {
        return null;
    }
    const date = format(parsedStartDate, 'MMM d, y');
    const text = translate('locationMap.meetingStartDate', { date });
    return React.createElement("span", { className: "location-details__start-date badge badge-pill badge-info" }, text);
};
const ConnectedVirtualLocationDetailPane = connectCurrentSelectedLocation(VirtualLocationDetailPane);
export default ConnectedVirtualLocationDetailPane;
