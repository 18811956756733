import { Entry, IEntry } from "../base";

export interface ISectionEmailCaptureFields {
  internalTitle?: never;
  bookmarkTitle?: string;
  title?: string;
  text?: string;
  placeholder?: string;
  buttonText?: string;
  disclaimer?: string;
  listId: string;
  groupId?: string;
}

/**
 * Section: Email Capture
 * A section which presents an input for the user to provide us their email address
 */
export interface ISectionEmailCapture extends IEntry<ISectionEmailCaptureFields> {
}

export function isSectionEmailCapture(entry: IEntry<any>): entry is ISectionEmailCapture {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-email-capture'
}

export class SectionEmailCapture extends Entry<ISectionEmailCaptureFields> implements ISectionEmailCapture {
  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get title(): string | undefined {
    return this.fields.title
  }

  get text(): string | undefined {
    return this.fields.text
  }

  get placeholder(): string | undefined {
    return this.fields.placeholder
  }

  get buttonText(): string | undefined {
    return this.fields.buttonText
  }

  get button_text(): string | undefined {
    return this.fields.buttonText
  }

  get disclaimer(): string | undefined {
    return this.fields.disclaimer
  }

  get listId(): string {
    return this.fields.listId
  }

  get list_id(): string {
    return this.fields.listId
  }

  get groupId(): string | undefined {
    return this.fields.groupId
  }

  get group_id(): string | undefined {
    return this.fields.groupId
  }

  constructor(entry: ISectionEmailCapture);
  constructor(id: string, fields: ISectionEmailCaptureFields);
  constructor(entryOrId: ISectionEmailCapture | string, fields?: ISectionEmailCaptureFields) {
    super(entryOrId, 'section-email-capture', fields)
  }
}
