import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IPage, Page } from "./page";

export interface ISectionProductListFields {
  internalTitle?: never;
  collectionId: string;
  attributionAccountId: number;
  bulkPurchasingPage?: ILink<'Entry'> | IPage;
}

/**
 * Section: Shopify Product List
 * The Shopify Product List displays products that are available for purchase via Shopify.
 */
export interface ISectionProductList extends IEntry<ISectionProductListFields> {
}

export function isSectionProductList(entry: IEntry<any>): entry is ISectionProductList {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-product-list'
}

export class SectionProductList extends Entry<ISectionProductListFields> implements ISectionProductList {
  get collectionId(): string {
    return this.fields.collectionId
  }

  get collection_id(): string {
    return this.fields.collectionId
  }

  get attributionAccountId(): number {
    return this.fields.attributionAccountId
  }

  get attribution_account_id(): number {
    return this.fields.attributionAccountId
  }

  get bulkPurchasingPage(): Page | null | undefined {
    return !this.fields.bulkPurchasingPage ? undefined :
      (isEntry(this.fields.bulkPurchasingPage) ? wrap<'page'>(this.fields.bulkPurchasingPage) : null)
  }

  get bulk_purchasing_page(): Page | null | undefined {
    return !this.fields.bulkPurchasingPage ? undefined :
      (isEntry(this.fields.bulkPurchasingPage) ? wrap<'page'>(this.fields.bulkPurchasingPage) : null)
  }

  constructor(entry: ISectionProductList);
  constructor(id: string, fields: ISectionProductListFields);
  constructor(entryOrId: ISectionProductList | string, fields?: ISectionProductListFields) {
    super(entryOrId, 'section-product-list', fields)
  }
}
