import { AlgoliaSearch, HiddenFacets } from "@watermarkchurch/react-instantsearch-components"
import { Configure } from 'react-instantsearch-dom'
import React from "react"
import IUser from "../../lib/models/user"
import { IMinistry } from "../../lib/contentful/generated"
import { Hit } from "react-instantsearch/connectors"
import IAlgoliaAccount from "../../lib/models/algolia-account"
import { useCreateInvitation } from '../../lib/hooks/useCreateInvitation'
import { ConnectedAccountList } from "./account-hits"
import { ConnectedSearchBox } from "./custom-search-box"
import { ModalText } from "./modal-text"

interface IProps {
  user: IUser
  securedApiKey: string
  indexName: string
  ministry: IMinistry
  securityLevelId: number
  reassignment?: boolean
  onNewChurchSelected: (value: boolean) => void
}

export function ChurchPicker(props: IProps) {
  const [typing, setTyping] = React.useState(false)
  const [hitsPerPage, setHitsPerPage] = React.useState(10)
  const [selected, setSelected] = React.useState<Hit<IAlgoliaAccount> | null>(null)
  const [selecting, setSelecting] = React.useState(false)

  const { user, indexName, securedApiKey, ministry, onNewChurchSelected } = props

  const [{data, error, wait}, createInvitation] = useCreateInvitation()

  let submitButton: JSX.Element

  if (props.reassignment) {
    submitButton = <button className="btn btn-primary">Reassign</button>
  } else if (wait) {
    submitButton = <button className="btn btn-primary send-email" disabled={true}>Signing In...</button>
  } else {
    submitButton = <button className="btn btn-primary send-email" disabled={!selected}>Sign In</button>
  }


  if (data && !props.reassignment) {
    return <>
      <h4 className="self-service__form-question">
        Please check your email for a verification notice.
      </h4>
      <h4 className="self-service__form-question" style={{ padding: 0 }}>
        Click the link in the email message to verify your email address <strong>{data.invitation.email}</strong>.
      </h4>
      <div className="account-search__submit" style={{ textAlign: 'center', }}>
        <a className='btn btn-primary send-email' style={{ marginBottom: '8px' }} href={`${window.location.href}?assertNeverGotEmail=true`}>
          Resend Verification
        </a>
        <p>Didn&apos;t receive your verification email? <a href='/contact'>Contact us for help</a></p>
      </div>
    </>
  }

  return (<>
    <ModalText user={user} reassignment={props.reassignment} />
    <AlgoliaSearch indexName={indexName} widgetName="account-search" apiKey={securedApiKey}>
      <Configure hitsPerPage={hitsPerPage} aroundLatLngViaIP={true} aroundRadius={'all'} />
      <HiddenFacets hiddenFacets={{ self_serve_opt_out: false }} />
      <form className="self-service__form-container" onSubmit={async (event) => {
        event.preventDefault()
        const inviteProps = {
          user: user,
          ministryId: ministry.sys.id,
          accountId: selected.objectID,
          securityLevelId: props.securityLevelId,
          reassign: false,
        }

        if (props.reassignment) {
          inviteProps.reassign = true
        }

        await createInvitation(null, inviteProps)

        if (props.reassignment) {
          window.location.href = window.location.pathname
        }
      }}>
        <ConnectedSearchBox
          onChange={(event) => {
            setTyping(event.currentTarget.value !== '')

            if (selected) {
              setSelected(null)
            }
          }
          }
          onClick={(event) => {
            event.preventDefault()
            setTyping(true)
          }}
          onBlur={(event) => {
            event.preventDefault()
            if(event.currentTarget.value === '' && !selecting) {
              setTyping(false)
            } else if(event.currentTarget.value !== '' && selected) {
              setTyping(false)
            }
          }}
          name={selected?.name || ''} />
        <div className="dropdown-menu" style={{ display: typing ? 'flex' : 'none', flexDirection: 'column', maxHeight: '302.5px', overflow: 'scroll' }}>
          <ConnectedAccountList reassign={props.reassignment} onClick={selectChurch}/>
        </div>
        <div className="account-search__submit">
          {error && <div className="alert alert-danger" role="alert">
            Something went wrong:
            {error.message || error.toString()}
          </div>}

          {submitButton}

          {props.reassignment ? <a className="btn btn-link ml-auto" onClick={() => history.back()}>Go Back</a> :
            <a className='btn btn-link ml-auto' href={`/sign_out?origin=${window.location.href}`}
              rel='nofollow'
              data-method="delete">
              Go Back
            </a>
          }
        </div>
      </form>
    </AlgoliaSearch>
  </>)

  function selectChurch(hit: Hit<IAlgoliaAccount> | null = null): (event: React.MouseEvent<HTMLButtonElement>) => void {
    setSelecting(true)

    return (event) => {
      event.preventDefault()

      if (hit) {
        setSelected(hit)
        setSelecting(false)
        setTyping(false)
      } else {
        onNewChurchSelected(true)
        setSelecting(false)
      }
    }
  }
}
