import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { IPage, Page } from "./page";

export interface IRedirectFields {
  internalTitle: string;
  slug: string;
  externalLink?: string;
  pageLink?: ILink<'Entry'> | IPage;
  sectionLink?: ILink<'Entry'> | IEntry<any>;
  httpStatusCode: RedirectHttpStatusCode;
}

export type RedirectHttpStatusCode = 301 | 302;

/**
 * Redirect
 */
export interface IRedirect extends IEntry<IRedirectFields> {
}

export function isRedirect(entry: IEntry<any>): entry is IRedirect {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'redirect'
}

export class Redirect extends Entry<IRedirectFields> implements IRedirect {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get slug(): string {
    return this.fields.slug
  }

  get externalLink(): string | undefined {
    return this.fields.externalLink
  }

  get external_link(): string | undefined {
    return this.fields.externalLink
  }

  get pageLink(): Page | null | undefined {
    return !this.fields.pageLink ? undefined :
      (isEntry(this.fields.pageLink) ? wrap<'page'>(this.fields.pageLink) : null)
  }

  get page_link(): Page | null | undefined {
    return !this.fields.pageLink ? undefined :
      (isEntry(this.fields.pageLink) ? wrap<'page'>(this.fields.pageLink) : null)
  }

  get sectionLink(): IEntry<any> | null | undefined {
    return !this.fields.sectionLink ? undefined :
      (isEntry(this.fields.sectionLink) ? wrap(this.fields.sectionLink) : null)
  }

  get section_link(): IEntry<any> | null | undefined {
    return !this.fields.sectionLink ? undefined :
      (isEntry(this.fields.sectionLink) ? wrap(this.fields.sectionLink) : null)
  }

  get httpStatusCode(): RedirectHttpStatusCode {
    return this.fields.httpStatusCode
  }

  get http_status_code(): RedirectHttpStatusCode {
    return this.fields.httpStatusCode
  }

  constructor(entry: IRedirect);
  constructor(id: string, fields: IRedirectFields);
  constructor(entryOrId: IRedirect | string, fields?: IRedirectFields) {
    super(entryOrId, 'redirect', fields)
  }
}
