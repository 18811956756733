import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { ISectionEmailCapture, SectionEmailCapture } from "./section-email-capture";

export interface ISectionBlockTextFields {
  internalTitle: string;
  bookmarkTitle?: string;
  body: string;
  emailCapture?: ILink<'Entry'> | ISectionEmailCapture;
}

/**
 * Section: Block Text
 * Markdown free-text block
 */
export interface ISectionBlockText extends IEntry<ISectionBlockTextFields> {
}

export function isSectionBlockText(entry: IEntry<any>): entry is ISectionBlockText {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-block-text'
}

export class SectionBlockText extends Entry<ISectionBlockTextFields> implements ISectionBlockText {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get body(): string {
    return this.fields.body
  }

  get emailCapture(): SectionEmailCapture | null | undefined {
    return !this.fields.emailCapture ? undefined :
      (isEntry(this.fields.emailCapture) ? wrap<'section-email-capture'>(this.fields.emailCapture) : null)
  }

  get email_capture(): SectionEmailCapture | null | undefined {
    return !this.fields.emailCapture ? undefined :
      (isEntry(this.fields.emailCapture) ? wrap<'section-email-capture'>(this.fields.emailCapture) : null)
  }

  constructor(entry: ISectionBlockText);
  constructor(id: string, fields: ISectionBlockTextFields);
  constructor(entryOrId: ISectionBlockText | string, fields?: ISectionBlockTextFields) {
    super(entryOrId, 'section-block-text', fields)
  }
}
